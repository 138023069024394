import React from 'react';
import { Tag } from 'antd';

export default class Ranking extends React.Component {

    constructor(props){

    super(props);

        this.state = {
        }
    }

    render() {

        let rowColor
        const { total, display, label } = this.props

        if(total > -1 && total < 109){
            rowColor = "#faad14"
        }

        if(total > 108 && total < 216){
            rowColor = "#FA8C19"
        }

        if(total > 216){
            rowColor = "#f5222d"
        }

        return (
            <>
                <Tag color={rowColor} style={{display: display}}>{label} {total}</Tag>
            </>

        )
    }
}