import React from 'react'
import { compose } from 'recompose';
import { withFirebase } from '../Firebase'
import { withLocalize } from "react-localize-redux"
import { withAuthorization } from '../Session'
import {withRouter} from 'react-router';
import NotFound from '../NotFound';
import PrismicReact from 'prismic-reactjs'
import {  Spinner,   } from 'reactstrap';
import { Row, Col, Card, Typography} from 'antd';
import Prismic from 'prismic-javascript'
import { Icon } from 'antd';

const { Title } = Typography;
class ContentPage extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      notFound: false,
      topic: null,
      notFoundTopic: null,
      meny: null
    }

    if (props.prismicCtx) {
      this.fetchPage(props);

    }
    
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps)
    this.props = nextProps;
    this.fetchPage(nextProps)
  }


  componentDidUpdate(prevProps) {
    //this.props.prismicCtx.toolbar();
    // We fetch the page only after it's ready to query the api
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
    }
  }

  fetchPage(props) {

    let langsel = 'sv-se'
    if(props.language == "en"){
      langsel = 'en-gb'
    }
    let result
    // console.log("props.match.params.uid", props.match.params.uid)

    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      props.prismicCtx.api.query(
        Prismic.Predicates.at('my.page.uid', props.match.params.uid), 
        { lang : langsel }, (err, doc) => {
        if (doc) {
          result = doc.results[0]
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc: result });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFound: !result });
        }
      });
      // props.prismicCtx.api.getByUID('page', props.match.params.uid, { lang : langsel }, (err, doc) => {
      //   if (doc) {
      //     // We put the retrieved content in the state as a doc variable
      //     this.setState({ doc });
      //   } else {
      //     // We changed the state to display error not found if no matched doc
      //     this.setState({ notFound: !doc });
      //   }
      // });

      let that = this
      props.prismicCtx.api.query(
          Prismic.Predicates.at("document.tags", [this.props.match.params.subid]), { orderings : '[my.content.section]' }
      ).then(function(response) {
        if (response) {
          // console.log("response", response)
          that.setState({ meny: response });
        } else {
          that.setState({ meny: !response });
        }
      });

    }

   



    return null;
  }

  render() {

    if (this.state.doc) {
      
      const { meny } = this.state
      let that = this
      let startPage = ''
      if( that.props.match.params.subid==="Steg" ){
        startPage = 'steps/Steg'
      }
      if( that.props.match.params.subid==="Om oss" ){
        startPage = 'about/Om oss'
      }

      return (
        <>
        <Row gutter={16}>
          <Col span={24}>
            <Title>{PrismicReact.RichText.render(this.state.doc.data.title)}</Title>
          </Col>
        </Row>

        <Row gutter={16}>
            <Col span={24}>
              <Card>
                {PrismicReact.RichText.render(this.state.doc.data.intro)}
              </Card>              
            </Col>
        </Row>

        <Row gutter={16}>
            <Col span={5}>              
              {meny && <Card>
                <p><Icon type="read" /> <a href={`/page/${startPage}`}>Start</a></p>
                {
                 meny.results.map(function(item) {
                    return (                  
                      <p><Icon type="read" /> <a href={`/page/${item.uid}/${that.props.match.params.subid}`}>{item.data.title[0].text} </a></p>
                    )
                  })
                }
              </Card>}         
            </Col>
            <Col span={19}>
              <Card>
              
              <p>{PrismicReact.RichText.render(this.state.doc.data.description, this.props.prismicCtx.linkResolver)}</p>
              </Card>

              <Col md="9" style={{marginTop: 0}}>
                  {
                  this.state.doc.data.body.map(function(content) {
                    
                    if(content.slice_type =="only_text"){
                        return(<><Card>
                          <Row>
                            <Col className="ml-auto mr-auto" md="12">
                              <h2 className="title-first">{content.primary.title[0] && content.primary.title[0].text}</h2>
                              <p className="description">
                                {PrismicReact.RichText.render(content.primary.content)}
                              </p>                         
                            </Col>
                          </Row></Card>
                        </>)
                    }


                  if(content.slice_type =="2_columns"){
                    return(<>
                      <Row><Card>
                        {
                          content.items.map(function(item){
                            return(<>
                            <Col className="ml-auto mr-auto" md="6">
                              <h2 className="">{item.column_1[0].text}</h2>
                              {/* <p className="">{item.content[0].text}</p>          */}
                              {PrismicReact.RichText.render(content.primary.content)}                   
                            </Col>                          
                            </>)
                          }
                        )}
                      </Card></Row>
                    </>)
                  }


                  if(content.slice_type =="image_to_the_right"){
                    return(<>
                      <Row><Card>
                        <Col md="6">
                          <h2 className="">{content.primary.title[0].text}</h2>
                          <p className="">
                            {PrismicReact.RichText.render(content.primary.content)}
                          </p>                      
                        </Col>
                        <Col md="6">
                          <img src={content.primary.image.url} alt="" class="rounded img-raised"/>                     
                        </Col>  

                        </Card></Row>
                    </>)
                  }


                  if(content.slice_type =="image_to_the_left"){
                    return(<>
                      <Row><Card>
                        <Col md="6">
                          <img src={content.primary.image.url} alt="" class="rounded img-raised"/>                         
                        </Col>                          
                        <Col md="6">
                          <h2 className="">{content.primary.title[0].text}</h2>
                          <p className="">{content.primary.content[0].text}</p>                       
                        </Col>    
                        </Card></Row>
                    </>)
                  }
                 
                })
              }
                </Col>
              
            </Col>
        </Row>

         
      </>

      );
    } 
    else if (this.state.notFound) {
      return <NotFound />;
    }

    return  <Spinner color="primary" />;

  }
}


const condition = authUser => !!authUser;

// export default ContentPage

export default compose(
  withLocalize,
  withFirebase,
  withRouter,
  withAuthorization(condition)

)(ContentPage);
