import React from 'react'

import { compose } from 'recompose';
import { withFirebase } from '../Firebase'
import { withLocalize } from "react-localize-redux"
import { withAuthorization } from '../Session'

import NotFound from '../NotFound';
import {  Spinner,   } from 'reactstrap';
import { Row, Col, Card, Icon , Typography, Button, Divider} from 'antd'
import { Translate } from "react-localize-redux"
import PrismicReact from 'prismic-reactjs'
import Prismic from 'prismic-javascript'
const { Title } = Typography
// const Prismic = require('prismic-javascript')


class Knowledge extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      action: null,
      notFound: false,
      actionsections: null,
      topic: null
    }

    if (props.prismicCtx) {
      this.fetchTopics(props)
      this.fetchPage(props)
    }
    
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.prismicCtx) {
      this.fetchTopics(this.props)
      this.fetchPage(this.props)
    }
  }

  fetchTopics(props) {

    let langsel = 'sv-se'
    if(props.language == "en"){
      langsel = 'en-gb'
    }
    let result

    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
    //  return props.prismicCtx.api.query(
    //     Prismic.Predicates.at('my.page.actionsection'), 
    //     { lang : langsel }, (err, doc) => {
    //     if (doc) {
    //       result = doc.results[0]
    //       // We put the retrieved content in the state as a doc variable
    //       this.setState({ doc: result });
    //     } else {
    //       // We changed the state to display error not found if no matched doc
    //       this.setState({ notFound: !result });
    //     }
    //   });

      return props.prismicCtx.api.query(
        Prismic.Predicates.at('document.type', 'actionsection'), 
        { orderings : '[my.actionsection.orderby]', lang : langsel  }, (err, actionsections) => {
        if (actionsections) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ actionsections });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFoundTopic: !actionsections });
        }
      });
    }
    return null;
  }

  handleClick(actionsectionid, that){

    // console.log("actionsectionid", actionsectionid)
    let langsel = 'sv-se'
    if(this.props.language == "en"){
      langsel = 'en-gb'
    }

    if (that.props.prismicCtx) {

      // We are using the function to get a document by its uid
      return that.props.prismicCtx.api.query(
        // Prismic.Predicates.at('document.type', 'action'),
        Prismic.Predicates.at('my.action.actionsection', actionsectionid),
        { lang : langsel  }
      ).then(function(action) {

        
        that.setState({ action: action });
      });
       
    }
    return null
  }

  fetchPage(props) {

    let langsel = 'sv-se'
    if(props.language == "en"){
      langsel = 'en-gb'
    }
    
    if (props.prismicCtx) {
      let that = this
      return props.prismicCtx.api.getByUID('page', 'knowledge', { lang : langsel }).then(function(page, err){
          if (page) {
            that.setState({ page: page });
          } else {
            that.setState({ notFound: !page });
          }
      })
    }
    return null;

  }

  render() {

    const {actionsections, page, action} = this.state
    const { language } = this.props
    if (actionsections && page) {
      let that = this
      return (
        <>
        <Row gutter={16}>
          <Col span={24}>
            <Title><Icon type="read" /> <Translate id='knowledge.title' /></Title>
          </Col>
        </Row>
        <Row gutter={16}>
            <Col span={24}>
              <Card>
              {/* <p>{page.data.description[0].text}</p> */}
              {PrismicReact.RichText.render(page.data.description)}      
              </Card>
              
            </Col>
        </Row>

        <Row gutter={16}>
            <Col span={6}>
              <Card>
                {actionsections.results.map(function(actionsection) {
                  console.log("actionsection", actionsection)
                    return (<>
                      <Button type="link" className="menuLink"  onClick={(e) => that.handleClick(actionsection.id, that)}><Icon type="read" /> {actionsection.data.name[0].text}</Button> <br></br></>
                    )
                  })
                }
              </Card>              
            </Col>
            <Col span={18}>
              

              {action && action.results.map(function(item){
                  return(<>
                  <Card>
                    <h2>{PrismicReact.RichText.render(item.data.title)}</h2>
                    {PrismicReact.RichText.render(item.data.desc)}       
                    {PrismicReact.RichText.render(item.data.longdescription)}
                  </Card>                          
                  </>)
                }
              )}

            </Col>
        </Row>

        
      </>

      );
    } 
    else if (this.state.notFound) {
      return <NotFound />;
    }

    return  <Spinner color="primary" />;

  }
}

const condition = authUser => !!authUser;

export default compose(
  withLocalize,
  withFirebase,
  withAuthorization(condition)

)(Knowledge);
