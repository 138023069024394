import React from 'react'
import { Row, Col, Typography, Card, Icon, Collapse, Select, Input, Divider} from 'antd'
import { Link } from 'react-router-dom'
import { withFirebase } from '../Firebase'
import PriorityItems from './PriorityItem'
import { compose } from 'recompose'
import { withAuthorization } from '../Session'
import { connect } from 'react-redux'
import { withLocalize } from "react-localize-redux"
import {Translate} from 'react-localize-redux'
import Ranking from '../Ranking'

const Prismic = require('prismic-javascript')
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

class Priority extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      notFound: false,
      questions: null,
      notFoundQuestions: null,
      current: 0,
      value: ['0-0-0'],
      displayactions: 'none', 
      totalProblems: 0,
      prioProblems: 0,
      survey: null,
      page: null,
      surveyResults: null      
    }

    if (props.prismicCtx) {
      this.fetchPage(props);
      //this.fetchQuestions(props);
    }

    this.onChange = this.onChange.bind(this)
    // this.createTable = this.createTable.bind(this)
    this.handleChange = this.handleChange.bind(this)

  }

  handleChange(value) {

    let val = value.split("-")
    let rankId = val[0]
    let selectedValue = val[1]
    let selectId = val[2]

    this.setState({[rankId +"_"+ selectId]: selectedValue})
  
    this.props.firebase.survey(this.props.surveys[0].uid +"/prioritySelections/").update({
        [rankId +"_"+ selectId]: selectedValue
    })

    this.updateRowRankTotal(rankId, selectedValue, selectId)

    if(this.state[rankId +"_clicked"] == undefined){
        this.state[rankId +"_clicked"] = 0
    }

    const totalClick = this.state[rankId +"_clicked"] + 1;
    this.setState({[rankId +"_clicked"]: totalClick})

    console.log("totalClick", totalClick)

    if(totalClick == 5){
        const prioProblems = this.state.prioProblems + 1
        console.log("prioProblems", prioProblems)
        this.setState({ prioProblems })
        this.updatePrioProblems(prioProblems)
    }
  }

  handleCommentChange(questionindex, event){
    this.props.firebase.survey(this.props.surveys[0].uid +"/questionPrioComments/"+ questionindex).update({
        ["comment"] : event.target.value
    })
  }

  checkAllRanks(rankId, selectedValue, selectId){

    console.log("checkAllRanks", selectedValue)
    
    if(this.state[rankId + "_0"] === undefined){
      this.state[rankId + "_0"] = 1;
    }

    if(this.state[rankId + "_1"] === undefined){
        this.state[rankId + "_1"] = 1;
    }

    if(this.state[rankId + "_2"] === undefined){
        this.state[rankId + "_2"] = 1;
    }
    
    if(this.state[rankId + "_3"] === undefined){
        this.state[rankId + "_3"] = 1;
    }

    if(this.state[rankId + "_4"] === undefined){
        this.state[rankId + "_4"] = 1;
    }
    
    // this.setState({[rankId +"_"+ selectId]: selectedValue})
    
    // this.state[rankId + "_"+ selectId] = selectedValue;
    
    
}

  updateRowRankTotal(rankId, selectedValue, selectId){

    this.checkAllRanks(rankId, selectedValue, selectId)

    // this.state[rankId + "_"+ selectId] = parseInt(selectedValue)

    // console.log("ranks", this.state[rankId + "_0"], this.state[rankId + "_1"], this.state[rankId + "_2"] , this.state[rankId + "_3"], this.state[rankId + "_4"])
    
    let rowTotal = this.state[rankId + "_0"] *  this.state[rankId + "_1"] *  this.state[rankId + "_2"] *  this.state[rankId + "_3"] *  this.state[rankId + "_4"]
    
    // this.setState({[rankId +"_"+ selectId]: selectedValue})

    console.log("rowTotal rowTotal rowTotal", rowTotal)

    this.setState({[rankId +"_total"]: rowTotal})

    this.props.firebase.survey(this.props.surveys[0].uid +"/priorityRank/").update({
        [rankId]: rowTotal
    })
    
    console.log("rank_total", rankId +"_total")
    console.log("rowTotal", rowTotal)

    this.updatePriorityRanking(rankId +"_total", rowTotal)
    // this.props.updatePriorityRanking(rankId, rowTotal)
    
}


  onChange = value => {
    // console.log('onChange ', value);
    this.setState({ value });
  };


  componentDidUpdate(prevProps) {

    if (this.props.prismicCtx) {
      this.props.prismicCtx.toolbar();
    }
    
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
      this.fetchQuestions(this.props);
    }

    if(prevProps.language !== this.props.language){
      // console.log("update")
      this.fetchPage(this.props);
    }

  }

  fetchPage(props) {
    let langsel = 'sv-se'
    if(props.language == "en"){
      langsel = 'en-gb'
    }
    if (props.prismicCtx) {
      let that = this
      return props.prismicCtx.api.getByUID('page', 'priopage', { lang : langsel }).then(function(page, err){
          if (page) {
            that.setState({ page: page });
          } else {
            that.setState({ notFound: !page });
          }
      })
    }
    return null;
  }

  fetchQuestions(props) {
    let langsel = 'sv-se'
    if(props.language == "en"){
      langsel = 'en-gb'
    }
    if (props.prismicCtx) {
      return props.prismicCtx.api.query(
        Prismic.Predicates.at('document.type', 'question'), 
        { lang : langsel, orderings : '[my.topic.orderby desc]' }, (err, doc) => {
        if (doc) {
          this.setState({ doc });
        } else {
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;

  }

  goBack(){
    this.props.history.push("/survey/"+ this.props.match.params.pid);
  }

  updatePrioProblems = (prioProblems) => {
    if(this.state.totalProblems === prioProblems){
      this.setState({ displayactions: 'block' })
      this.props.firebase.survey(this.props.location.state.survey.uid).update({
        hasPrio: true
      })
    }
  }

  checkForPrioProblems = () => {
    console.log("this.props.surveys[", this.props.surveys)
    if(this.props.surveys[0]["priorityRank"]){
      if(this.state.totalProblems === Object.keys(this.props.surveys[0]["priorityRank"]).length){
        this.setState({ displayactions: 'block' })
      }
    }    
  }

  updateTotalProblems = (totalProblems) => {
    this.setState({ totalProblems: totalProblems })
  }

  updatePriorityRanking = (priorityRanking, value) => {
    //console.log("updatePriorityRanking", priorityRanking, value)
    this.setState({ [priorityRanking]: value })
  }

  updatePriorityRankingRowTotal = (priorityRanking, value) => {
    //console.log("updatePriorityRankingRowTotal", priorityRanking, value)
    this.setState({ [priorityRanking]: value })
    this.onListenForSurveys()
  }

  onListenForSurveys = () => {
    this.props.firebase
      .surveys()
      .orderByChild("uniqueid")
      .equalTo(this.props.authUser.uid +"_"+ this.props.match.params.uid)
      .limitToLast(100)
      .on('value', snapshot => {
        
        console.log("snapshot", snapshot.val())

        this.props.onSetSurveys(snapshot.val())
        
        this.setState({ survey: snapshot.val() })
        this.setState({ surveyResults: this.props.surveys })
        this.checkForPrioProblems(snapshot.val())
        
        this.checkIfPrioIsSet()

      })
  }

  checkIfPrioIsSet(){
    let totalProblems = 0   
    this.props.surveys[0].sourcesAnswers.map(function(question, questionindex) {
        var i = 0;
        let total = parseInt(question["topic_"+ questionindex +"_total"]) + 1
        for (i = 0; i < total; i++) {
            let value = question["topic_"+ questionindex +"_value_"+ i]
            let showInRapportHsr = question["topic_"+ questionindex +"_questionshowinrapport_"+ i] 
            if(value === 3 && questionindex > 0 && showInRapportHsr){
                totalProblems++
            }
        }        
    })

    console.log("checkIfPrioIsSet", totalProblems)

    this.setState({totalProblems: totalProblems})
  }
  

  componentDidMount(){ 
    this.onListenForSurveys()    
  }

  // createTable(survey, total, question, questionindex, that)
  // {
  //   let panels = []
  //   for (var i = 0; i < total; i++) {
                
  //       let value = question["topic_"+ questionindex +"_value_"+ i]
  //       let showInRapportHsr = question["topic_"+ questionindex +"_questionshowinrapport_"+ i]
        
  //       if(value === 3 && questionindex > 0 && showInRapportHsr === true){
        
  //           let uniqueid = questionindex +"_"+ i
            
  //           let header = question["topic_"+ questionindex +"_questionlabel_"+ i]
  //           let key = question["topic_"+ questionindex +"_questionlabel_"+ i]
  //           let answer = question["topic_"+ questionindex +"_question_"+ i]
  //           let comment = question["topic_"+ questionindex +"_comment_"+ i]
            
  //           let rowTotal = 0
  //           // console.log("survey", survey)

  //           if(survey["priorityRank"]){

  //               console.log("uniqueid", uniqueid)
  //               if(survey.priorityRank[uniqueid]){
  //                   rowTotal =  survey.priorityRank[uniqueid]
  //                   console.log("rowTotalrowTotal" ,rowTotal)
  //               }
  //           }
        
  //           let prioComment = ''
  //           if(survey["questionPrioComments"]){
  //               if(survey.questionPrioComments[uniqueid]){
  //                   prioComment = survey.questionPrioComments[uniqueid].comment
  //               }
  //           }

  //           let selectedValue0
  //           let selectedValue1
  //           let selectedValue2
  //           let selectedValue3
  //           let selectedValue4

  //           if(survey.prioritySelections){

  //               if(survey.prioritySelections[uniqueid +"_0"] ){
  //                   selectedValue0 = survey.prioritySelections[uniqueid +"_0"] 
  //               }

  //               if(survey.prioritySelections[uniqueid +"_1"] ){
  //                   selectedValue1 = survey.prioritySelections[uniqueid+"_1"] 
  //               }

  //               if(survey.prioritySelections[uniqueid +"_2"] ){
  //                   selectedValue2 = survey.prioritySelections[uniqueid+"_2"] 
  //               }

  //               if(survey.prioritySelections[uniqueid +"_3"] ){
  //                   selectedValue3 = survey.prioritySelections[uniqueid+"_3"] 
  //               }

  //               if(survey.prioritySelections[uniqueid +"_4"] ){
  //                   selectedValue4 = survey.prioritySelections[uniqueid+"_4"] 
  //               }
  //           }
                
            
  //           let commentSpan = 24
  //           if(comment){
  //               commentSpan = 12
  //           }

  //           panels.push(
                
  //               <Panel header={
  //                 <Row gutter={16}>
  //                   <Col span={21}>{header}</Col>
  //                   <Col span={2}>
  //                     <Ranking total={rowTotal} label={<Translate id="priority.rank"/>}></Ranking>
  //                   </Col>
  //                 </Row>} key={key}>                    
  //                   <Row gutter={16}>
  //                       <Col span={commentSpan}>
  //                           <Card bordered={false} className="ant-card-inline-nospace">
  //                               <Translate id="priority.youranwser"/>
  //                               <h3 style={{textAlign:"center"}}>{answer}</h3> 
  //                               <Divider />
  //                               <Ranking total={rowTotal} label={<Translate id="priority.rankfromprio"/>}></Ranking>
  //                           </Card>
  //                       </Col>

  //                       {comment &&(<>
  //                           <Col span={12}> 
  //                           <Card bordered={false} className="ant-card-inline-nospace">
  //                           <Translate id="priority.yourresponsecomments"/>
  //                           <p class="ingress">{comment}</p>
  //                           </Card>
  //                       </Col></>)}
                        
  //                   </Row>
        
  //                   <Row gutter={16}>

  //                       <Col span={24}>

  //                           <Card className="ant-card-inline-nospace" size="small">
  //                               <Col span={24}>
  //                                   <Translate id="priority.legal"/>
  //                               </Col>
  //                               <Col span={12}>
                                    
  //                                   <p class="ingress">Är problemet relaterat till några rättsliga (nationalla eller lokala) krav inom kommunen?</p> 
  //                               </Col>
  //                               <Col span={12}>
  //                                   <Select 
  //                               suffixIcon={<Icon type="bank" />} 
  //                               defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
  //                                       <Option value={uniqueid + "-1-0"}><Translate id="priority.no"/></Option>
  //                                       <Option value={uniqueid + "-3-0"}><Translate id="priority.yes"/></Option>
  //                                   </Select>
  //                               </Col>

  //                           </Card>

  //                       </Col>
                    
  //                   </Row>

  //                   <Row gutter={16}>
  //                       <Col span={24}>
  //                           <Card className="ant-card-inline-nospace" size="small">
  //                               <Col span={24}>
  //                                   <Translate id="priority.strategi"/>
  //                               </Col>
  //                               <Col span={12}>
  //                                   <p class="ingress">Är problemet relaterat till några (nationella eller lokala) strategiska mål inom kommunen?</p>
  //                               </Col>
  //                               <Col span={12}>
  //                                   <Select suffixIcon={<Icon type="sound" />} defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
  //                                       <Option value={uniqueid + "-1-1"}><Translate id="priority.no"/></Option>
  //                                       <Option value={uniqueid + "-3-1"}><Translate id="priority.yes"/></Option>
  //                                   </Select>
  //                               </Col>
  //                           </Card>
  //                       </Col>
  //                   </Row>

  //                   <Row gutter={16}>
  //                       <Col span={24}>
  //                           <Card className="ant-card-inline-nospace" size="small">
  //                               <Col span={24}>
  //                                   <Translate id="priority.economy"/>
  //                               </Col>
  //                               <Col span={12}>
  //                                   <p class="ingress">Kan detta problem potentiellt orsaka (direkta eller indirekta) finansiella kostnader?</p>
  //                               </Col>
  //                               <Col span={12}>
  //                                   <Select name={key}   suffixIcon={<Icon type="dollar" />} defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
  //                                   <Option value={uniqueid + "-1-2"}><Translate id="priority.no"/></Option>
  //                                   <Option value={uniqueid + "-2-2"}><Translate id="priority.yeslow"/></Option>
  //                                   <Option value={uniqueid + "-3-2"}><Translate id="priority.yessignificant"/></Option>
  //                                   </Select>
  //                               </Col>
  //                           </Card>
  //                       </Col>
  //                   </Row>

  //                   <Row gutter={16}>
  //                       <Col span={24}>
  //                           <Card className="ant-card-inline-nospace" size="small">
  //                               <Col span={24}>
  //                                   <Translate id="priority.social"/>
  //                               </Col>
  //                               <Col span={12}>
  //                                   <p class="ingress">Är detta problem viktigt för intressenter (allmänheten, näringslivet, media etc.)? </p>
  //                               </Col>
  //                               <Col span={12}>
  //                                       <Select name={key}  suffixIcon={<Icon type="usergroup-delete" />} defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
  //                                       <Option value={uniqueid + "-1-3"}><Translate id="priority.notimportant"/></Option>
  //                                       <Option value={uniqueid + "-2-3"}><Translate id="priority.smallimportance"/></Option>
  //                                       <Option value={uniqueid + "-3-3"}><Translate id="priority.greatimportance"/></Option>
  //                                   </Select>
  //                               </Col>
  //                           </Card>
  //                       </Col>
  //                   </Row>

  //                   <Row gutter={16}>
  //                       <Col span={24}>
  //                           <Card className="ant-card-inline-nospace" size="small">
  //                               <Col span={24}>
  //                                   <Translate id="priority.enviroment"/>
  //                               </Col>
  //                               <Col span={12}>
  //                                   <p class="ingress"> Hur stor mängd marint skräp kan potentiellt nå havet på grund av detta problem? </p>
  //                               </Col>
  //                               <Col span={12}>

  //                                   <Select name={key}   suffixIcon={<Icon type="global" />} defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
  //                                   <Option value={uniqueid + "-1-4"}><Translate id="priority.small"/></Option>
  //                                   <Option value={uniqueid + "-2-4"}><Translate id="priority.medium"/></Option>
  //                                   <Option value={uniqueid + "-3-4"}><Translate id="priority.big"/></Option>
  //                                   </Select>
                                    
  //                               </Col>
  //                           </Card>
  //                       </Col>
  //                   </Row>

  //                   <Card className="ant-card-inline-nospace" size="small">
  //                       <Col span={24}> 
  //                           <Translate id="priority.yourcomments"/>
  //                           <TextArea type="textarea" rows={4} onChange={(e) => that.handleCommentChange(uniqueid, e)} defaultValue={prioComment}></TextArea>
                                
  //                       </Col>
  //                   </Card>

  //               </Panel>

  //           )

  //       }
    
  //   }

  //   return panels
  // }

  render() {

    let survey 
    const { page, displayactions, surveyResults } = this.state
    if(surveyResults && page)
    {
      survey = surveyResults[0]
      const that = this
      let panels = []

      return (
        <>
        <Row gutter={16}>
          <Col span={12}>
            <Title><Link to={`/survey/${survey.randomstring}`} class="backlink"><Icon type="double-left" /></Link> <Icon type="fire" /> {page.data.title[0].text}</Title>
          </Col>
          <Col span={12}>
            <Link 
            to={{
              pathname: `/action/${survey.randomstring}`,
              state: {
                survey: survey
              }
            }}
            style={{color:'white', float:'right', display: displayactions}} 
            size="large" 
            class="ant-btn ant-btn-primary ant-btn-lg">
            <Icon type="check-circle" /> <Translate id="priority.next"/> 
            </Link>
          </Col>
        </Row>
        
        <Row gutter={16}>
          <Col span={24}>
            <span class="pathPartName"><Translate id="priority.for"/>:</span> <Link to={`/survey/${survey.randomstring}`} ><span class="pathPartTitle">{survey.name}</span></Link>
            <br/><br/>
          </Col>
        </Row>
        
        <Row gutter={16}>
            <Col span={24}>
              <Card>
                <p>{page.data.description[0].text}</p>
              </Card>
              
            </Col>
            
        </Row>

  
        <Title class="pathPart"><Icon type="alert" /> <Translate id="priority.activity"/></Title>
        
       
        {        
          survey.sourcesAnswers.map(function(question, questionindex) {

            let total = parseInt(question["topic_"+ questionindex +"_total"]) + 1

            // console.log("############### total here", total)
            
            for (var i = 0; i < total; i++) {

              // console.log("questionindex", questionindex)

              //let i = questionindex
              //return that.createTable(survey, total, question, questionindex, that)   

              // console.log("******* theI", i)

              let value = question["topic_"+ questionindex +"_value_"+ i]

              // console.log("ma value", value)

              let showInRapportHsr = question["topic_"+ questionindex +"_questionshowinrapport_"+ i]

              // console.log("showInRapportHsr", showInRapportHsr)
              
              if(value === 3 && questionindex > 0 && showInRapportHsr === true){
            
                let uniqueid = questionindex +"_"+ i
                
                let header = question["topic_"+ questionindex +"_questionlabel_"+ i]
                let key = question["topic_"+ questionindex +"_questionlabel_"+ i]
                let answer = question["topic_"+ questionindex +"_question_"+ i]
                let comment = question["topic_"+ questionindex +"_comment_"+ i]
                
                let rowTotal = 0
                // console.log("survey", survey)
    
                if(survey["priorityRank"]){                     
                    if(survey.priorityRank[uniqueid]){
                      // console.log("priorityRank uniqueid", uniqueid, that.state[uniqueid])
                      rowTotal = that.state[uniqueid +"_total"] //survey.priorityRank[uniqueid]
                    }
                }
            
                let prioComment = ''
                if(survey["questionPrioComments"]){
                    if(survey.questionPrioComments[uniqueid]){
                        prioComment = survey.questionPrioComments[uniqueid].comment
                    }
                }
    
                let selectedValue0
                let selectedValue1
                let selectedValue2
                let selectedValue3
                let selectedValue4
    
                if(survey.prioritySelections){
    
                    if(survey.prioritySelections[uniqueid +"_0"] ){
                        selectedValue0 = survey.prioritySelections[uniqueid +"_0"] 
                    }
    
                    if(survey.prioritySelections[uniqueid +"_1"] ){
                        selectedValue1 = survey.prioritySelections[uniqueid+"_1"] 
                    }
    
                    if(survey.prioritySelections[uniqueid +"_2"] ){
                        selectedValue2 = survey.prioritySelections[uniqueid+"_2"] 
                    }
    
                    if(survey.prioritySelections[uniqueid +"_3"] ){
                        selectedValue3 = survey.prioritySelections[uniqueid+"_3"] 
                    }
    
                    if(survey.prioritySelections[uniqueid +"_4"] ){
                        selectedValue4 = survey.prioritySelections[uniqueid+"_4"] 
                    }
                }
                    
                
                let commentSpan = 24
                if(comment){
                    commentSpan = 12
                }
    
                panels.push(
                  <Collapse accordion bordered={false}>
                    <Panel header={
                      <Row gutter={16}>
                        <Col span={21}>{header}</Col>
                        <Col span={2}>
                          <Ranking total={rowTotal} label={<Translate id="priority.rank"/>}></Ranking>
                        </Col>
                      </Row>} key={key}>                    
                        <Row gutter={16}>
                            <Col span={commentSpan}>
                                <Card bordered={false} className="ant-card-inline-nospace">
                                    <Translate id="priority.youranwser"/>
                                    <h3 style={{textAlign:"center"}}>{answer}</h3> 
                                    <Divider />
                                    <Ranking total={rowTotal} label={<Translate id="priority.rankfromprio"/>}></Ranking>
                                </Card>
                            </Col>
    
                            {comment &&(<>
                                <Col span={12}> 
                                <Card bordered={false} className="ant-card-inline-nospace">
                                <Translate id="priority.yourresponsecomments"/>
                                <p class="ingress">{comment}</p>
                                </Card>
                            </Col></>)}
                            
                        </Row>
            
                        <Row gutter={16}>
    
                            <Col span={24}>
    
                                <Card className="ant-card-inline-nospace" size="small">
                                    <Col span={24}>
                                        <Translate id="priority.legal"/>
                                    </Col>
                                    <Col span={12}>
                                        
                                        <p class="ingress"> {that.props.language === 'en' ? 'Is the problem related to any legal (national or local) requirements within the municipality' : 'Är problemet relaterat till några rättsliga (nationalla eller lokala) krav inom kommunen?'}</p> 
                                    </Col>
                                    <Col span={12}>
                                        <Select 
                                    suffixIcon={<Icon type="bank" />} 
                                    defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
                                            <Option value={uniqueid + "-1-0"}><Translate id="priority.no"/></Option>
                                            <Option value={uniqueid + "-3-0"}><Translate id="priority.yes"/></Option>
                                        </Select>
                                    </Col>
    
                                </Card>
    
                            </Col>
                        
                        </Row>
    
                        <Row gutter={16}>
                            <Col span={24}>
                                <Card className="ant-card-inline-nospace" size="small">
                                    <Col span={24}>
                                        <Translate id="priority.strategi"/>
                                    </Col>
                                    <Col span={12}>
                                        <p class="ingress">{that.props.language === 'en' ? 'Is the problem related to any (national or local) strategic goals within the municipality?' : 'Är problemet relaterat till några (nationella eller lokala) strategiska mål inom kommunen?'}</p>
                                    </Col>
                                    <Col span={12}>
                                        <Select suffixIcon={<Icon type="sound" />} defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
                                            <Option value={uniqueid + "-1-1"}><Translate id="priority.no"/></Option>
                                            <Option value={uniqueid + "-3-1"}><Translate id="priority.yes"/></Option>
                                        </Select>
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
    
                        <Row gutter={16}>
                            <Col span={24}>
                                <Card className="ant-card-inline-nospace" size="small">
                                    <Col span={24}>
                                        <Translate id="priority.economy"/>
                                    </Col>
                                    <Col span={12}>
                                        <p class="ingress">{that.props.language === 'en' ? 'Can this problem potentially cause (direct or indirect) financial costs?' : 'Kan detta problem potentiellt orsaka (direkta eller indirekta) finansiella kostnader?'}</p>
                                    </Col>
                                    <Col span={12}>
                                        <Select name={key}   suffixIcon={<Icon type="dollar" />} defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
                                        <Option value={uniqueid + "-1-2"}><Translate id="priority.no"/></Option>
                                        <Option value={uniqueid + "-2-2"}><Translate id="priority.yeslow"/></Option>
                                        <Option value={uniqueid + "-3-2"}><Translate id="priority.yessignificant"/></Option>
                                        </Select>
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
    
                        <Row gutter={16}>
                            <Col span={24}>
                                <Card className="ant-card-inline-nospace" size="small">
                                    <Col span={24}>
                                        <Translate id="priority.social"/>
                                    </Col>
                                    <Col span={12}>
                                        <p class="ingress">{that.props.language === 'en' ? 'Is this problem important for stakeholders (public, business, media, etc.)?' : 'Är detta problem viktigt för intressenter (allmänheten, näringslivet, media etc.)?'} </p>
                                    </Col>
                                    <Col span={12}>
                                            <Select name={key}  suffixIcon={<Icon type="usergroup-delete" />} defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
                                            <Option value={uniqueid + "-1-3"}><Translate id="priority.notimportant"/></Option>
                                            <Option value={uniqueid + "-2-3"}><Translate id="priority.smallimportance"/></Option>
                                            <Option value={uniqueid + "-3-3"}><Translate id="priority.greatimportance"/></Option>
                                        </Select>
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
    
                        <Row gutter={16}>
                            <Col span={24}>
                                <Card className="ant-card-inline-nospace" size="small">
                                    <Col span={24}>
                                        <Translate id="priority.enviroment"/>
                                    </Col>
                                    <Col span={12}>
                                        <p class="ingress">{that.props.language === 'en' ? 'How much marine litter can potentially reach the sea because of this problem?' : 'Hur stor mängd marint skräp kan potentiellt nå havet på grund av detta problem?'}  </p>
                                    </Col>
                                    <Col span={12}>
    
                                        <Select name={key}   suffixIcon={<Icon type="global" />} defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
                                        <Option value={uniqueid + "-1-4"}><Translate id="priority.small"/></Option>
                                        <Option value={uniqueid + "-2-4"}><Translate id="priority.medium"/></Option>
                                        <Option value={uniqueid + "-3-4"}><Translate id="priority.big"/></Option>
                                        </Select>
                                        
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
    
                        <Card className="ant-card-inline-nospace" size="small">
                            <Col span={24}> 
                                <Translate id="priority.yourcomments"/>
                                <TextArea type="textarea" rows={4} onChange={(e) => that.handleCommentChange(uniqueid, e)} defaultValue={prioComment}></TextArea>
                                    
                            </Col>
                        </Card>
    
                    </Panel>
                  </Collapse>
                )
              }               
            }            
          })         
          
        }
       
        { panels }

        {/* <PriorityItems survey={survey} 
          updatePriorityRankingRowTotal={this.updatePriorityRankingRowTotal} 
          updatePriorityRanking={this.updatePriorityRanking} 
          updateTotalProblems={this.updateTotalProblems} 
          updatePrioProblems={this.updatePrioProblems}
        /> */}
                  
        <br>
        </br>

        <Row gutter={16}>        
          <Col span={24}>
            <Link 
            to={{
              pathname: `/action/${survey.randomstring}`,
              state: {
                survey: survey
              }
            }}
            style={{color:'white', float:'right', display: this.state.displayactions}} 
            size="large" class="ant-btn ant-btn-primary ant-btn-lg">
              <Icon type="check-circle" /> <Translate id="priority.next"/>
            </Link>
          </Col>
        </Row>     
      
      </>

      )
    }
    else{
      return null
    }
    

  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  surveys: Object.keys(state.surveyState.surveys || {}).map(
    key => ({
      ...state.surveyState.surveys[key],
      uid: key,
    })
  ),
  limit: state.surveyState.limit
});

const mapDispatchToProps = dispatch => ({
  onSetSurveys: surveys =>
    dispatch({ type: 'SURVEYS_SET', surveys }),
  onSetSurveysLimit: limit =>
    dispatch({ type: 'SURVEYS_LIMIT_SET', limit }),
});

//export default withFirebase(Priority)

const condition = authUser => !!authUser;

export default compose(
  withLocalize,
  withFirebase,
  withAuthorization(condition),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(Priority);