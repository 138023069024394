import React from 'react';
import {  Row, Col  } from 'reactstrap';
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";

const Footer = () => (
  <div>
    
    <Row className="no-print">
        <Col lg="12"> 
            
            <small>Håll Sverige Rent</small>
        </Col>
    </Row>
  
  </div>
);

export default withLocalize(Footer);
