import React from 'react'

import { compose } from 'recompose'
import { withFirebase } from '../Firebase'
import { withLocalize } from "react-localize-redux"
import { withAuthorization } from '../Session'
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Canvas } from '@react-pdf/renderer';

import NotFound from '../NotFound'
import { Row, Col, Spin, Typography, Card, Icon, Collapse, TreeSelect, Select, Divider } from 'antd'
import { Link } from 'react-router-dom'
import ActionItem from '../Action/ActionItem'


const Prismic = require('prismic-javascript')
const { Title } = Typography

const style={
  width: '100px',
  height: '100px'
}

const styleLogo={
  width: '200px',
  height: '130px'
}

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

class RaportPdf extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      notFound: false,
      questions: null,
      notFoundQuestions: null,
      current: 0,
      value: ['0-0-0'],
    }

    if (props.prismicCtx) {
      //this.fetchPage(props);
      //this.fetchQuestions(props);
    }

  }


  componentDidUpdate(prevProps) {
  
    // We fetch the page only after it's ready to query the api
    if (!prevProps.prismicCtx) {
      //this.fetchPage(this.props);
      this.fetchQuestions(this.props);
    }
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getByUID('page', props.match.params.uid, { lang : this.props.language }, (err, doc) => {
        if (doc) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }

  fetchQuestions(props) {

    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.query(
        Prismic.Predicates.at('my.question.belongstosource', props.match.params.uid), 
        { orderings : '[my.question.question]' }, (err, questions) => {
        if (questions) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ questions });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFoundQuestions: !questions });
        }
      });
    }
    return null;
  }

  printMe(){
    window.print()
  }

  goBack(){
    this.props.history.push("/survey/"+ this.props.match.params.pid);
  }

  render() {

    const { survey } = this.props.location.state
    let prismic = this.props.prismicCtx

    const that = this
    const panels = []



    const MyDocument = () => (
      
        <Document>

          <Page size="A4" style={styles.page}>
            
            <View style={styles.section}>
              <Canvas>

                {survey.sourcesAnswers.map(function(question, questionindex) {

              
                  let total = parseInt(question["topic_"+ questionindex +"_total"]) + 1

                  for (var i = 0; i < total; i++) {

                    let value = question["topic_"+ questionindex +"_value_"+ i]

                    if(value === 3 && questionindex > 0){
                      
                      // console.log("questionindex", questionindex)
                      // console.log("i", i)

                      let uniqueid = questionindex +"_"+ i
                      let header = question["topic_"+ questionindex +"_questionlabel_"+ i]
                      let answer = question["topic_"+ questionindex +"_question_"+ i]
                      let comment = question["topic_"+ questionindex +"_comment_"+ i]
                      let questionid = question["topic_"+ questionindex +"_questionid_"+ i]

                      let prioComments
                      if(survey["questionPrioComments"]) {
                        prioComments = survey.questionPrioComments[questionindex +"_"+ i]
                      }
                    
                      let showInRapport = survey.showInRapport[uniqueid +"_question"]
                      let showInRapportComment = survey.showInRapport[questionindex +"_"+ i +"_comment"]
                      let showInRapportCommentPrio = survey.showInRapport[uniqueid +"_commentPrio"]
                      let showInRapportActions = survey.showInRapport[uniqueid +"_actions"]
                      

                      let commentSpan = 12
                      if(comment){
                          commentSpan = 24
                      }

                      let commentSpanPrio = 12
                      if(prioComments){
                        commentSpanPrio = 24
                      }
                    
                      if(showInRapport === undefined || showInRapport !== false){

                        panels.push(

      
                          <Row gutter={16}>

                            <Col offset={4} span={16}>

                                <h4>{header}</h4>
                                <br></br>
                                <h3>Svar: {answer}</h3>
                                <br></br>

                                <Row gutter={16}>

                                  {showInRapportComment === undefined || showInRapportComment !== false &&(
                                      comment &&(<Col span={commentSpan}>
                                      <Card className="ant-card-inline"><h5>Era kommentarer från kartläggning</h5>
                                        {comment}</Card>
                                      </Col>)
                                    )
                                  }
                                
                                  {showInRapportCommentPrio === undefined || showInRapportCommentPrio !== false &&(
                                      prioComments &&(<Col span={commentSpanPrio}>
                                        <Card className="ant-card-inline"><h5>Era kommentarer från prioritering</h5>
                                        {prioComments && prioComments.comment}</Card>
                                      </Col>)
                                    )
                                  }
                                </Row>
                                
                                {showInRapportActions === undefined || showInRapportActions !== false &&(
                                    prioComments &&(<Row gutter={24}><Col span={24}>
                                      <ActionItem prismicCtx={prismic}  uniqueid={uniqueid} questionid={questionid} surveyid={survey.uid} showExtra={false}/>
                                    </Col></Row>)
                                  )
                                }
                                  
                            </Col>
                          </Row>
                      
                        )

                      }
                    } 
                  }

                })
                }
                
                <Card>
                  <Row gutter={18}>
                    

                    <Col span={3} offset={6}>
                      <Image src={`/static/media/icons/socioekonomi.png`} style={style}/> 
                    </Col>
                    <Col span={3}>
                      <Image src={`/static/media/icons/turism.png`} style={style}/> 
                    </Col>
                    <Col span={3}>
                      <Image src={`/static/media/icons/nedskrapning.png`} style={style}/> 
                    </Col>
                    <Col span={3}>
                      <Image src={`/static/media/icons/avloppsnat.png`} style={style}/> 
                    </Col>
                  </Row>
                  
                  <Row gutter={2}>
                    <Col span={3} offset={6}>
                      <Image src={`/static/media/icons/insamling.png`} style={style}/> 
                    </Col>
                    <Col span={3}>
                      <Image src={`/static/media/icons/soptunna.png`} style={style}/> 
                    </Col>
                    <Col span={3}>
                      <Image src={`/static/media/icons/industri.png`} style={style}/> 
                    </Col>
                    <Col span={3}>
                      <Image src={`/static/media/icons/jordbruk.png`} style={style}/> 
                    </Col>
                  </Row>
                
                  <Row gutter={18}>
                    <Col span={14} offset={4} style={{textAlign: "center"}}>
                      <h1>Rapport från kartläggning av <br></br>källor och spridningsvägar <br></br>för marint skräp</h1>
                    </Col>
                  </Row>
                  <Row gutter={18}>
                    <Col span={14} offset={4} style={{textAlign: "center"}}>
                      <h3>Namn på kommun</h3>
                      <h3>November 2019</h3>
                    </Col>
                  </Row>
                  
                  <Row gutter={18}>
                    <Col span={14} offset={4} style={{textAlign: "center"}}>
                    <Image src={`/static/media/images/logo.jpg`} style={styleLogo}/> 
                    </Col>
                  </Row>
                
                  <Row gutter={18}>
                    <Col span={14} offset={4} style={{textAlign: "center"}}>
                      <h3>Arbetsgrupp i kommun</h3>
                      <h3>November 2019</h3>
                    </Col>
                  </Row>
                  <Row gutter={18}>
                    <Col offset={4} span={16} style={{textAlign: "left"}}>
                      <h5>Ansvarig för kartläggning:</h5>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nulla lacus, vehicula id imperdiet cursus, iaculis eu urna.</p>
                      <h5>Övriga deltagare:</h5>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nulla lacus, vehicula id imperdiet cursus, iaculis eu urna.</p>
                    </Col>
                  </Row>
                
              
                  <Row gutter={18}>
                    <Col offset={4} span={16}>

                      <h3>Metoden för kartläggning av källor och spridningsvägar för marint skräp är framtagen i EU-projektet BLASTIC.</h3>

                      <p>Projektet pågick mellan 2016-2018 och finansierades av Centrala Östersjöprogrammet. Förutom Håll Sverige Rent som ledde projektet deltog även Håll Skärgården Ren (Finland), IVL Svenska Miljöinstitutet, Stockholm Environmental Institute Tallin, SYKE (Finland), Fee Lativa, Åbo kommun och Tallins kommun. <br></br><br></br>Det digitala verktyget för kartläggningen har finansierats av Naturvårdsverket.<br></br><br></br>Kartläggningen fungerar som en vägledning för kommuner som själva står för ifyllda svar och prioritering av åtgärder.</p>
                    </Col>
                  </Row>
                
                
              
                  <Row gutter={18}>
                    <Col offset={4} span={16}>

                      <h3 style={{textAlign: "center"}}>Om Håll Sverige Rent</h3>

                      <p>Skräpet finns överallt – i staden, i naturen och i havet. Håll Sverige Rent arbetar för att förebygga och motverka nedskräpningen. Vi samlar kunskap, driver opinion och tar fram konkreta verktyg och lösningar för att stoppa skräpet. <br></br><br></br>Håll Sverige Rent är en ideell obunden stiftelse. Arbetet finansieras av bidrag och projektmedel från företag och myndigheter samt genom insamling till 90-konto. Håll Sverige Rent är medlem i Giva Sverige och Svensk Insamlingskontroll.</p>
                    </Col>
                  </Row>
                
                  <Row gutter={18}>
                    <Col span={14} offset={4} style={{textAlign: "center"}}>
                    <Image src={`/static/media/images/logo.jpg`} style={styleLogo}/> 
                    </Col>
                  </Row>
                  

                  {panels}

                </Card>

              </Canvas>

            </View>

          </Page>

        </Document>
    )

    return (
      <PDFViewer>
        <MyDocument />
      </PDFViewer>
        
    )

  }
}



const condition = authUser => !!authUser;

export default compose(
  withLocalize,
  withFirebase,
  withAuthorization(condition)

)(RaportPdf);

