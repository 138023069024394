import React, { Component } from 'react';
import { Col, Switch, Icon, Row, Card} from 'antd';
import { withFirebase } from '../Firebase';

const Prismic = require('prismic-javascript');

class ActionItem extends Component {

    constructor(props) {
    
      super(props)

      this.state = {
        actions: null
      }
    
      this.handleChange = this.handleChange.bind(this);

    }

    fetchActions() {

      let langsel = 'sv-se'
      if(this.props.language == "en"){
        langsel = 'en-gb'
      }

      console.log("langsel", langsel)

      if(this.props){

        if (this.props.prismicCtx) {

          return this.props.prismicCtx.api.query(
            Prismic.Predicates.at('document.id', this.props.questionid), { lang : langsel, 'fetchLinks' : ['action.title', 'action.desc'] }, (err, actions) => {
            if (actions) {
              this.setState({ actions })
            } else {
              this.setState({ notFoundQuestions: !actions });
            }
            
          });


        }
      }
      return null;
    }

    handleChange = (value, e) => {

      this.setState({ [value] : e})

      this.props.firebase.survey(this.props.surveyid +"/showInRapport/").update({
        [value]: e
      })

    }
    componentDidUpdate(prevProps) {

  
      if(prevProps.language !== this.props.language){
        // console.log("update")
        this.fetchActions()
      }
  
    }
    componentDidMount(){
      
      this.fetchActions()
    }

    render() 
    {

      const { actions } = this.state

      let that = this
      let thisOnOffActions = ''
      const {showExtra, showInRapportActions } = this.props

      let extraAction = null
      let card = null
      let checked = true

      if(showInRapportActions === undefined || showInRapportActions === false) {
        checked = false
      }else{
        checked = true
      }

      if(showExtra){
        extraAction = <Switch 
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />}
                      checked={checked} onChange={(e) => that.handleChange(that.props.uniqueid +"_actions", e)}  size="small" />
      }

      if(actions){
        
        if(that.state!==null){

          if(that.state[that.props.uniqueid +"_actions"]==false || showInRapportActions === false){
            thisOnOffActions = 'none'
          }
          else{
            thisOnOffActions = ''
          }

        }

        let list = actions.results.map(function(action) {


          return (
            
            action.data.actions[0].action.slug && (<>
            <Row style={{marginBottom:10}}>
              <Col span={22}>
              <h5>{that.props.language === 'en' ? 'Suggestions for measure' : 'Förslag på åtgärder'}</h5> 
              </Col>
             
            </Row>
            <Row>
              {
                action.data.actions.map(function(actionInner) {
                  // console.log("actionInner", actionInner)
                  return (
                    actionInner.action.data &&(
                    <p style={{display: thisOnOffActions}}>
                      <div style={{paddingBottom:10}}>
                        <Icon type="bulb" /> <strong >{actionInner.action.data.title[0].text}</strong> 
                        <br></br>
                      </div>                     
                      {that.props.showDetails && actionInner.action.data.desc && actionInner.action.data.desc[0].text}
                    </p>
                    )
                  )
                })
              }
            </Row>
            </>)
            
          )
        })

        return(
          <>{list}</>
        )

      }
      else{
        return null
      }
      
    }
}

export default withFirebase(ActionItem);
