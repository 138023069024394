import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Icon, Menu } from 'antd';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import * as ROLES from '../../constants/roles';

const { SubMenu } = Menu;

const Navigation = ({ authUser, firebase, setActiveLanguage, setLanguage }) =>
  authUser ? (
    <NavigationAuth authUser={authUser} firebase={firebase} setActiveLanguage={setActiveLanguage} setLanguage={setLanguage}/>
  ) : (
    <NavigationNonAuth setActiveLanguage={setActiveLanguage} setLanguage={setLanguage}/>
  );

class NavigationAuth extends React.Component {

  handleClick = e => {
    // console.log('click ', e);
    this.props.setActiveLanguage(e.key)
    this.props.setLanguage(e.key)
  }

  render() {

    const {authUser} = this.props

    // console.log("authUser.roles.includes(ROLES.ADMIN)", authUser.roles.ADMIN)

    return (
      <Menu
        className="no-print"
        mode="horizontal"
        style={{ lineHeight: '64px', float: 'right' }}
      >
        <Menu.Item key="1"><Link to={ROUTES.HOME} /> <Icon type="home" /> <Translate id="menu.home" /></Menu.Item>
        <Menu.Item key="2"><Link to="/page/steps/Steg" /><Icon type="question-circle" /> <Translate id="menu.help" /></Menu.Item>
        {/* <Menu.Item key="3"><Link to="/account" /><Icon type="user" /> <Translate id="menu.profile" /></Menu.Item> */}
        <Menu.Item key="4"><Link to="/knowledge" /><Icon type="read" /> <Translate id="menu.bibel" /></Menu.Item>
        <Menu.Item key="55"><Link to="/page/about/Om%20oss" /><Icon type="read" /> <Translate id="menu.aboutus" /></Menu.Item>
  
        {authUser.roles.ADMIN && (<SubMenu
        title={
          <span className="submenu-title-wrapper">
            <Icon type="flag" />
            <Translate id="menu.language" />
          </span>
        }
        >
          <Menu.Item key="sv" onClick={this.handleClick}><Translate id="menu.swedish" /></Menu.Item>
          <Menu.Item key="en" onClick={this.handleClick}><Translate id="menu.english" /></Menu.Item> 

        </SubMenu>)}

        {authUser.roles.ADMIN && (<SubMenu
        title={
          <span className="submenu-title-wrapper">
            <Icon type="control" />
            <Translate id="menu.admin" />
          </span>
        }
      >
        {authUser.roles.ADMIN && ( <Menu.Item key="3"><Link to="/signup" /><Icon type="setting" /> <Translate id="menu.settings" /></Menu.Item>)}
        {authUser.roles.ADMIN && ( <Menu.Item key="33"><Link to="/signup" /><Icon type="project" /> <Translate id="menu.stat" /></Menu.Item>)}
        
        </SubMenu>)
      }

      <Menu.Item key="44" onClick={this.props.firebase.doSignOut}><Icon type="logout" /> <Translate id="menu.logout" /></Menu.Item>
  
      </Menu>
    )
  }
}

class NavigationNonAuth extends React.Component {

  handleClick = e => {
    // console.log('click ', e)
    this.props.setActiveLanguage(e.key)
    this.props.setLanguage(e.key)
  }


  render() 
  {
    return (
      <Menu 
        mode="horizontal"
        style={{ lineHeight: '64px', float: 'right' }}
      >
        {/* <Menu.Item key="1"><Link to={ROUTES.LANDING} /><Icon type="home" /> <Translate id="menu.home" /></Menu.Item> */}

        <SubMenu
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="flag" />
                  <Translate id="menu.language" />
                </span>
              }
            >
            <Menu.Item key="sv" onClick={this.handleClick}><Translate id="menu.swedish" /></Menu.Item>
            <Menu.Item key="en" onClick={this.handleClick}><Translate id="menu.english" /></Menu.Item>
        </SubMenu>

        {/* <Menu.Item key="2"><Link to={ROUTES.SIGN_IN} /><Icon type="login" /> <Translate id="menu.login" /></Menu.Item> */}

      </Menu>
      )
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

export default compose(
  withLocalize,
  withFirebase,
  connect(mapStateToProps)
)(Navigation);  