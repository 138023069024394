import React from 'react';


import { compose } from 'recompose';
import { withFirebase } from '../Firebase'
import { withLocalize } from "react-localize-redux"
import { withAuthorization } from '../Session'

import NotFound from '../NotFound';
import PrismicReact from 'prismic-reactjs';
import {  ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Spinner, Container, Row, Col, Progress  } from 'reactstrap';
	
var Prismic = require('prismic-javascript');

// Declare your component
class CheckList extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      notFound: false,
      topics: null,
      notFoundTopic: null
    }

    if (props.prismicCtx) {
      this.fetchPage(props);
      this.fetchTopics(props);
    }
    
  }

  componentDidUpdate(prevProps) {
    this.props.prismicCtx.toolbar();
    // We fetch the page only after it's ready to query the api
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
      this.fetchTopics(this.props);
    }
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getByUID('page', props.match.params.uid, { lang : this.props.language }, (err, doc) => {
        if (doc) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }

  fetchTopics(props) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.query(Prismic.Predicates.at('document.type', 'topic'), { orderings : '[my.topic.topic_title]' }, (err, topics) => {
        if (topics) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ topics });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFoundTopic: !topics });
        }
      });
    }
    return null;
  }

  render() {

    // console.log("this.props.language", this.props.language)
    let list;
    if (this.state.doc){

        // console.log("this.state.topic", this.state.topics)

        if(this.state.topics){
            
            list = this.state.topics.results.map(function(topic, index) {
            return (
              <ListGroupItem tag="a" href={`/question/${topic.id}`} action>
                <ListGroupItemHeading>{topic.data.topic_title[0].text}</ListGroupItemHeading>
                  <ListGroupItemText>
                    {topic.data.topic_description[0].text}
                  </ListGroupItemText>
                <Progress color="info" value={2 * 5} />
              </ListGroupItem>
            );
          });

        }
      
      return (

        <Container>

          <Row>
            <Col xs="12"> 
              <h1>{PrismicReact.RichText.render(this.state.doc.data.title)}</h1>
              <br></br>
              
            </Col>
          </Row>
          
          <Row>
            <Col xs="12">
              <ListGroup>
                {
                  this.state.topics && 
                    <p>{list}</p>
                }
              </ListGroup>
            </Col>
          </Row>
          
        </Container>

      );
    } 
    else if (this.state.notFound) {
      return <NotFound />;
    }

    return  <Spinner color="primary" />;

  }
}

const condition = authUser => !!authUser;

export default compose(
  withLocalize,
  withFirebase,
  withAuthorization(condition)

)(CheckList);

