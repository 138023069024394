import React, { Component } from 'react';
import { Row, Col, Icon, Collapse, Select, Card, Input, Divider} from 'antd';
import { withFirebase } from '../Firebase';
import Ranking from '../Ranking'
import { withLocalize } from "react-localize-redux"
import {Translate} from 'react-localize-redux'

const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

class ProrityItem extends Component {

    constructor(props) {

        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            doc: null,
            prioProblems : 0,
            totalProblems : 0
        }
    }


    handleCommentChange(questionindex, event){

        this.props.firebase.survey(this.props.survey.uid +"/questionPrioComments/"+ questionindex).update({
            ["comment"] : event.target.value
        })
    }

    handleChange = (value) => {


        console.log("value", value)

        let val = value.split("-")
        let rankId = val[0]
        let selectedValue = val[1]
        let selectId = val[2]

        this.setState({[rankId +"_"+ selectId]: selectedValue})
        this.updateRowRankTotal(rankId, selectedValue, selectId)

        this.props.firebase.survey(this.props.survey.uid +"/prioritySelections/").update({
            [rankId +"_"+ selectId]: selectedValue
        })

        if(this.state[rankId +"_clicked"] == undefined){
            this.state[rankId +"_clicked"] = 0
        }

        const totalClick = this.state[rankId +"_clicked"] + 1;
        this.setState({[rankId +"_clicked"]: totalClick})

        if(totalClick == 5){

            const prioProblems = this.state.prioProblems + 1;
            this.setState({ prioProblems })
            this.props.updatePrioProblems(prioProblems)
            

        }

        

    }

    checkAllRanks(rankId){

        if(this.state[rankId + "_0"] === undefined){
            this.state[rankId + "_0"] = 1;
        }
        
        if(this.state[rankId + "_1"] === undefined){
            this.state[rankId + "_1"] = 1;
        }

        if(this.state[rankId + "_2"] === undefined){
            this.state[rankId + "_2"] = 1;
        }
        
        if(this.state[rankId + "_3"] === undefined){
            this.state[rankId + "_3"] = 1;
        }

        if(this.state[rankId + "_4"] === undefined){
            this.state[rankId + "_4"] = 1;
        }
        
    }

    updateRowRankTotal(rankId, selectedValue, selectId){

        this.checkAllRanks(rankId)

        //this.state[rankId + "_"+ selectId] = parseInt(selectedValue)
        
        let rowTotal = this.state[rankId + "_0"] *  this.state[rankId + "_1"] *  this.state[rankId + "_2"] *  this.state[rankId + "_3"] *  this.state[rankId + "_4"]
        
        // console.log("rankId", rankId)

        this.setState({[rankId +"_total"]: rowTotal})

        this.props.firebase.survey(this.props.survey.uid +"/priorityRank/").update({
            [rankId]: rowTotal
        })
        
        console.log("rank_total", rankId +"_total")
        console.log("rowTotal", rowTotal)

        this.props.updatePriorityRanking(rankId +"_total", rowTotal)
        // this.props.updatePriorityRanking(rankId, rowTotal)
        
    }

    createTable = (survey, total, question, questionindex, that) => {
        
        // console.log("question",question)
        let panels = []
        for (var i = 0; i < total; i++) {
            
            
            let value = question["topic_"+ questionindex +"_value_"+ i]
            let showInRapportHsr = question["topic_"+ questionindex +"_questionshowinrapport_"+ i]
            
            if(value === 3 && questionindex > 0 && showInRapportHsr === true){
            
                let uniqueid = questionindex +"_"+ i
                
                let header = question["topic_"+ questionindex +"_questionlabel_"+ i]
                let key = question["topic_"+ questionindex +"_questionlabel_"+ i]
                let answer = question["topic_"+ questionindex +"_question_"+ i]
                let comment = question["topic_"+ questionindex +"_comment_"+ i]
                
                let rowTotal = 0
                // console.log("survey", survey)

                if(survey["priorityRank"]){

                    // console.log("uniqueid", uniqueid)
                    if(survey.priorityRank[uniqueid]){
                        rowTotal =  survey.priorityRank[uniqueid]
                    }
                }
            
                let prioComment = ''
                if(survey["questionPrioComments"]){
                    if(survey.questionPrioComments[uniqueid]){
                        prioComment = survey.questionPrioComments[uniqueid].comment
                    }
                }

                let selectedValue0
                let selectedValue1
                let selectedValue2
                let selectedValue3
                let selectedValue4

                if(survey.prioritySelections){

                    if(survey.prioritySelections[uniqueid +"_0"] ){
                        selectedValue0 = survey.prioritySelections[uniqueid +"_0"] 
                    }

                    if(survey.prioritySelections[uniqueid +"_1"] ){
                        selectedValue1 = survey.prioritySelections[uniqueid+"_1"] 
                    }

                    if(survey.prioritySelections[uniqueid +"_2"] ){
                        selectedValue2 = survey.prioritySelections[uniqueid+"_2"] 
                    }

                    if(survey.prioritySelections[uniqueid +"_3"] ){
                        selectedValue3 = survey.prioritySelections[uniqueid+"_3"] 
                    }

                    if(survey.prioritySelections[uniqueid +"_4"] ){
                        selectedValue4 = survey.prioritySelections[uniqueid+"_4"] 
                    }
                }
                    
                
                let commentSpan = 24
                if(comment){
                    commentSpan = 12
                }

                panels.push(
                    
                    <Panel header={<Row gutter={16}><Col span={21}>{header}</Col><Col span={2}><Ranking total={rowTotal} label={"Rankning: "}></Ranking></Col></Row>} key={key}>
                        
                        <Row gutter={16}>

                            <Col span={commentSpan}>
                                <Card bordered={false} className="ant-card-inline-nospace">
                                    <Translate id="priority.youranwser"/>
                                    <h3 style={{textAlign:"center"}}>{answer}</h3> 
                                    <Divider />
                                    <Ranking total={rowTotal} label={<Translate id="priority.rankfromprio"/>}></Ranking>
                                </Card>
                            </Col>

                            {comment &&(<>
                                <Col span={12}> 
                                <Card bordered={false} className="ant-card-inline-nospace">
                                <Translate id="priority.yourresponsecomments"/>
                                <p class="ingress">{comment}</p>
                                </Card>
                            </Col></>)}
                            
                        </Row>
            
                        <Row gutter={16}>

                            <Col span={24}>

                                <Card className="ant-card-inline-nospace" size="small">
                                    <Col span={24}>
                                        <Translate id="priority.legal"/>
                                    </Col>
                                    <Col span={12}>
                                        
                                        <p class="ingress">Är problemet relaterat till några rättsliga (nationella eller lokala) krav inom kommunen?</p> 
                                    </Col>
                                    <Col span={12}>
                                        <Select 
                                    suffixIcon={<Icon type="bank" />} 
                                    defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
                                            <Option value={uniqueid + "-1-0"}><Translate id="priority.no"/></Option>
                                            <Option value={uniqueid + "-3-0"}><Translate id="priority.yes"/></Option>
                                        </Select>
                                    </Col>

                                </Card>

                            </Col>
                        
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Card className="ant-card-inline-nospace" size="small">
                                    <Col span={24}>
                                        <Translate id="priority.strategi"/>
                                    </Col>
                                    <Col span={12}>
                                        <p class="ingress">Är problemet relaterat till några (nationella eller lokala) strategiska mål inom kommunen?</p>
                                    </Col>
                                    <Col span={12}>
                                        <Select suffixIcon={<Icon type="sound" />} defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
                                            <Option value={uniqueid + "-1-1"}><Translate id="priority.no"/></Option>
                                            <Option value={uniqueid + "-3-1"}><Translate id="priority.yes"/></Option>
                                        </Select>
                                    </Col>
                                </Card>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Card className="ant-card-inline-nospace" size="small">
                                    <Col span={24}>
                                        <Translate id="priority.economy"/>
                                    </Col>
                                    <Col span={12}>
                                        <p class="ingress">Kan detta problem potentiellt orsaka (direkta eller indirekta) finansiella kostnader?</p>
                                    </Col>
                                    <Col span={12}>
                                        <Select name={key}   suffixIcon={<Icon type="dollar" />} defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
                                        <Option value={uniqueid + "-1-2"}><Translate id="priority.no"/></Option>
                                        <Option value={uniqueid + "-2-2"}><Translate id="priority.yeslow"/></Option>
                                        <Option value={uniqueid + "-3-2"}><Translate id="priority.yessignificant"/></Option>
                                        </Select>
                                    </Col>
                                </Card>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Card className="ant-card-inline-nospace" size="small">
                                    <Col span={24}>
                                        <Translate id="priority.social"/>
                                    </Col>
                                    <Col span={12}>
                                        <p class="ingress">Är detta problem viktigt för intressenter (allmänheten, näringslivet, media etc.)? </p>
                                    </Col>
                                    <Col span={12}>
                                            <Select name={key}  suffixIcon={<Icon type="usergroup-delete" />} defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
                                            <Option value={uniqueid + "-1-3"}><Translate id="priority.notimportant"/></Option>
                                            <Option value={uniqueid + "-2-3"}><Translate id="priority.smallimportance"/></Option>
                                            <Option value={uniqueid + "-3-3"}><Translate id="priority.greatimportance"/></Option>
                                        </Select>
                                    </Col>
                                </Card>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Card className="ant-card-inline-nospace" size="small">
                                    <Col span={24}>
                                        <Translate id="priority.enviroment"/>
                                    </Col>
                                    <Col span={12}>
                                        <p class="ingress"> Hur stor mängd marint skräp kan potentiellt nå havet på grund av detta problem? </p>
                                    </Col>
                                    <Col span={12}>

                                        <Select name={key}   suffixIcon={<Icon type="global" />} defaultValue={<Translate id="priority.choose"/>} style={{ width: '100%' }} onChange={that.handleChange}>
                                        <Option value={uniqueid + "-1-4"}><Translate id="priority.small"/></Option>
                                        <Option value={uniqueid + "-2-4"}><Translate id="priority.medium"/></Option>
                                        <Option value={uniqueid + "-3-4"}><Translate id="priority.big"/></Option>
                                        </Select>
                                        
                                    </Col>
                                </Card>
                            </Col>
                        </Row>

                        <Card className="ant-card-inline-nospace" size="small">
                            <Col span={24}> 
                                <Translate id="priority.yourcomments"/>
                                <TextArea type="textarea" rows={4} onChange={(e) => that.handleCommentChange(uniqueid, e)} defaultValue={prioComment}></TextArea>
                                    
                            </Col>
                        </Card>

                    </Panel>

                )

            }
        
        }
  
        return panels
    }


    componentDidMount(){

        let totalProblems = 0
        // console.log("this.props.survey.", this.props.survey)
        this.props.survey.sourcesAnswers.map(function(question, questionindex) {
            var i = 0;
            let total = parseInt(question["topic_"+ questionindex +"_total"]) + 1
            for (i = 0; i < total; i++) {

                let value = question["topic_"+ questionindex +"_value_"+ i]
                let showInRapportHsr = question["topic_"+ questionindex +"_questionshowinrapport_"+ i] 
                if(value === 3 && questionindex > 0 && showInRapportHsr){
                    totalProblems++
                }
            }
            
        })
        this.props.updateTotalProblems(totalProblems)

    }

    render() {

        const { survey } = this.props
        const that = this

        if(survey){

            return (
                <Collapse accordion bordered={false}>

                    {survey.sourcesAnswers.map(function(question, questionindex) {

                        let total = parseInt(question["topic_"+ questionindex +"_total"]) + 1
                        return that.createTable(survey, total, question, questionindex, that)
                        
                        })
                    }
                </Collapse>
            )
        }

        return null
    }
}

export default withFirebase(ProrityItem);
