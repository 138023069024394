import React from 'react';
import { Row, Col, Typography, Card, Icon, Switch } from 'antd';
import { Link } from 'react-router-dom';
import ActionList from './ActionList';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { connect } from 'react-redux';

const Prismic = require('prismic-javascript');
const { Title } = Typography;

class Action extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      notFound: false,
      questions: null,
      notFoundQuestions: null,
      current: 0,
      value: ['0-0-0'],
      page:null

    }

    if (props.prismicCtx) {
      this.fetchPage(props);
      this.fetchQuestions(props);
    }

    this.onChange = this.onChange.bind(this);
    
  }

  onListenForSurveys = () => {

    this.props.firebase
      .surveys()
      .orderByChild("uniqueid")
      .equalTo(this.props.authUser.uid +"_"+ this.props.match.params.uid)
      .limitToLast(100)
      .on('value', snapshot => {

        this.props.onSetSurveys(snapshot.val())
        this.setState({ loading: false })
  
      });
  };

  componentDidMount() {
    this.onListenForSurveys();
  }


  onChange(checked) {
    // console.log(`switch to ${checked}`);
  }


  handleChange(value) {
    // console.log(`selected ${value}`);
  }

  onChange = value => {
    // console.log('onChange ', value);
    this.setState({ value });
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  componentDidUpdate(prevProps) {
    if (this.props.prismicCtx) {
      this.props.prismicCtx.toolbar();
    }
    
    // We fetch the page only after it's ready to query the api
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
      this.fetchQuestions(this.props);
    }

    if(prevProps.language !== this.props.language){
      // console.log("update")
      this.fetchPage(this.props);
    }

  }

  fetchPage(props) {
    let langsel = 'sv-se'
    if(props.language == "en"){
      langsel = 'en-gb'
    }
    if (props.prismicCtx) {
      let that = this
      return props.prismicCtx.api.getByUID('page', 'foreslagna-atgarderpage', { lang : langsel }).then(function(page, err){
          if (page) {
            that.setState({ page: page });
          } else {
            that.setState({ notFound: !page });
          }
      })
    }
    return null;
  }


  fetchQuestions(props) {

    let langsel = 'sv-se'
    if(props.language == "en"){
      langsel = 'en-gb'
    }

    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.query(
        Prismic.Predicates.at('my.question.belongstosource', props.match.params.uid), 
        { lang : langsel }, (err, questions) => {
        if (questions) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ questions });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFoundQuestions: !questions });
        }
      });
    }
    return null;
  }

  goBack(){
    this.props.history.push("/survey/"+ this.props.match.params.pid);
  }

  render() {

    const { surveys } = this.props
    const survey = surveys[0]
    const { page } = this.state

    if(survey && page){

      return (
          <>

          <Row gutter={16}>
            <Col span={24}>
              <Title>
                <Link to={{
                      pathname: `/priority/${survey.randomstring}`,
                      state: {
                        survey: survey
                      }
                    }} class="backlink"><Icon type="double-left" />
                  </Link> 
                  <Icon type="bulb" /> {page.data.title[0].text}
              </Title>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <span class="pathPartName">För:</span> <Link to={`/survey/${survey.randomstring}`}><span class="pathPartTitle">{survey.name}</span></Link>
              <br/><br/>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={18}>
              <Card>
               
                <p>{page.data.description[0].text}</p>
              </Card>
            </Col>
            <Col span={6}>
              <Card>
              
                <p>{this.props.language === 'en' ? 'Use' : 'Använd'}  <Switch onChange={this.onChange} defaultChecked size="small"/> {this.props.language === 'en' ? 'to include/exclude from the rapport.' : 'för att inkludera/exkludera från rapporten.'}</p>
                
              </Card>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24} style={{textAlign: 'right'}}>
              <Link to={{
                pathname: `/raport/${survey.randomstring}`,
                state: {
                  survey: survey
                }
              }} size="large" class="ant-btn ant-btn-primary ant-btn-lg">{this.props.language === 'en' ? 'Show rapport' : 'Visa rapport'}</Link>
              <br/><br/>
            </Col>
          </Row>

          <ActionList survey={survey} prismicCtx={this.props.prismicCtx} language={this.props.language}/>

          <Row gutter={16}>
            <Col span={24} style={{textAlign: 'right'}}>
              <Link 
              
              to={{
                pathname: `/raport/${survey.randomstring}`,
                state: {
                  survey: survey
                }
              }}
              size="large" class="ant-btn ant-btn-primary ant-btn-lg">{this.props.language === 'en' ? 'Show rapport' : 'Visa rapport'}</Link>
            </Col>
          </Row>

        </>

        )
      
    }
    else{
      return null
    }
  }
}


const condition = authUser => !!authUser;

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  surveys: Object.keys(state.surveyState.surveys || {}).map(
    key => ({
      ...state.surveyState.surveys[key],
      uid: key,
    })
  ),
  limit: state.surveyState.limit
});

const mapDispatchToProps = dispatch => ({
  onSetSurveys: surveys =>
    dispatch({ type: 'SURVEYS_SET', surveys }),
  onSetSurveysLimit: limit =>
    dispatch({ type: 'SURVEYS_LIMIT_SET', limit }),
});


export default compose(
  withFirebase,
  withAuthorization(condition),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(Action);
