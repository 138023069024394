import React from 'react';
import { compose } from 'recompose';
import { withAuthorization } from '../Session';
import NotFound from '../NotFound';
import { Row, Col, Spin, Skeleton, Progress, Card, Icon ,Typography, notification, Image, Avatar} from 'antd';
import { withFirebase } from '../Firebase';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { withLocalize } from "react-localize-redux"
import {Translate} from 'react-localize-redux'

const Prismic = require('prismic-javascript');
const { Title } = Typography;
const { Meta } = Card;

const style={
  width: '50px',
  height: '50px'
}

const startHereNotification = (type, message = 'Börja här', description = 'Var snäll och besvara på Generell socioekonomi och geografi innan vi kan gå vidare med resterande källor.')  => {
  notification[type]({
    duration: 0,
    message: message,
    description: description
  })
}


const goodWorkNotification = (type, message = 'Bra jobbat!', description = 'Nu kan ni gå vidare till prioritering.')  => {
  notification[type]({
    duration: 0,
    message: message,
    description: description
  })
}


class Survey extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      page: null,
      doc: null,
      notFound: false,
      topic: null,
      notFoundTopic: null,
      loadingSurveys: false,
      visible: false,
      loading: false
    }

    if (props.prismicCtx) {
      this.fetchTopics(props)
      this.fetchPage(props)
    }
    
  }
  
  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  handleCreate = () => {
    
    const { form } = this.formRef.props

    form.validateFields((err, values) => {

      if (err) {
        return
      }

      form.resetFields()
      
      this.props.firebase.surveys().push({
        name: values.title,
        description: values.description,
        owner: this.props.authUser.uid,
        createdAt: this.props.firebase.serverValue.TIMESTAMP,
        percentageCompleted: 0,
      })
  
      this.setState({ text: '' });
      this.setState({ visible: false });

    })
  }

  onListenForSurveys = () => {
    this.props.firebase
      .surveys()
      .orderByChild("uniqueid")
      .equalTo(this.props.authUser.uid +"_"+ this.props.match.params.uid)
      .limitToLast(100)
      .on('value', snapshot => {

        this.props.onSetSurveys(snapshot.val())
        this.setState({ loading: false })
        this.displayNotifications()

      })
  }

  displayNotifications(){

    const { surveys } = this.props
    if(surveys){
      const survey = surveys[0]
      if(survey){
        
        if(!survey.sourcesCompleted)
        {
          if(this.props.language == "en"){
            startHereNotification('info', 'Start here', 'Please answer the General and Geographical survey before we can continue.');
          }
          else{
            startHereNotification('info')
          }
            
        }
       
        

        if(survey.sourcesCompleted){
          if(survey.sourcesCompleted["0"] && survey.sourcesCompleted["1"] && survey.sourcesCompleted["2"] && survey.sourcesCompleted["3"] && survey.sourcesCompleted["4"] && survey.sourcesCompleted["5"] && survey.sourcesCompleted["6"] && survey.sourcesCompleted["7"] & localStorage.showedGoodWork == null){
            
            if(this.props.language == "en"){
              goodWorkNotification('success', 'Good work!', 'Now you can prioritize your sources.');
            }else{
              goodWorkNotification('success')
            }
            
            localStorage.setItem("showedGoodWork", true);
          }
        }
      }
    }
  }

  saveFormRef = formRef => {
    this.formRef = formRef
  };

  componentDidUpdate(prevProps) {

    if(this.props.prismicCtx){
      this.props.prismicCtx.toolbar()
    }

    if (!prevProps.prismicCtx) {
      this.fetchTopics(this.props)
      this.fetchPage(this.props)
    }

    if(prevProps.language !== this.props.language){
      this.fetchTopics(this.props)
      this.fetchPage(this.props)
    }

  }

  componentWillUnmount() {
    this.props.firebase.surveys().off()
    notification.destroy()
  }

  componentDidMount() {
    this.onListenForSurveys()

  }


  fetchTopics(props) {
    let langsel = 'sv-se'
    if(props.language == "en"){
      langsel = 'en-gb'
    }
    if (props.prismicCtx) {
      return props.prismicCtx.api.query(Prismic.Predicates.at('document.type', 'topic'), { lang : langsel, orderings : '[my.topic.orderby desc]'}, (err, topics) => {
        if (topics) {
          console.log(topics)
          this.setState({ topics });
        } else {
          this.setState({ notFoundTopic: !topics });
        }
      });
    }
    return null;
  }

  
  fetchPage(props) {
    let langsel = 'sv-se'
    if(props.language == "en"){
      langsel = 'en-gb'
    }
    if (props.prismicCtx) {
      let that = this
      return props.prismicCtx.api.getByUID('page', 'surveypage', { lang : langsel }).then(function(page, err){
          if (page) {
            that.setState({ page: page });
          } else {
            that.setState({ notFound: !page });
          }
      })
    }
    return null;
  }



  render() {

    const { page } = this.state;
    const { surveys } = this.props;
    const survey = surveys[0];
    let list;
    let surveyPercentageCompleted = 0
    let displayLinkPrio = 'none'

    const {uid} = this.props.match.params
    
    if (this.state.topics && survey && page) {

      if(this.state.topics){

          list = this.state.topics.results.map(function(topic, topicindex) {
            
            // console.log("topic", topic)

            let standOutCard = null
            let isStandOutCard = false
            let open = null
            let arrows = null
            let loading = true
            
            if(topic.data.pin_on_top === "Ja"){

              isStandOutCard = true
              standOutCard = "standOutCard"
              open = <Translate id="survey.open" />
              arrows = <Icon type="double-right" />

              if(survey.sourcesCompleted){
                
                if(!survey.sourcesCompleted[topicindex]){
              
                  open = <Translate id="survey.starthere" />
                  arrows = <Icon type="form" />

                }else{
                  open = <Translate id="survey.edit" />
                  arrows = <Icon type="edit" />
                }
              }
              else{
                open = <Translate id="survey.starthere" />
              }
              loading = false
          
            }
            else
            {
              if(survey.sourcesCompleted){
                if(survey.sourcesCompleted[0] === true){
                  if(!survey.sourcesCompleted[topicindex]){
                    open = <Translate id="survey.open" />
                    arrows = <Icon type="form" />
                  }else{
                    open = <Translate id="survey.edit" />
                    arrows = <Icon type="edit" />
                  }
                  loading = false
                }else{
                  open = null
                  arrows = null
                }
              }
            }

            let topicpercentage = 0;
            if(survey.sourcesPercentage){
              topicpercentage = survey.sourcesPercentage[topicindex]
            }

            return (

              <Col span={8}>

                <Card loading={loading} className={standOutCard} 
                actions={[
                <Link 
                  to={{
                    pathname: `/questions/${topic.id}/${uid}`,
                    state: {
                      topic: topic,
                      topicindex: topicindex,
                      survey: survey
                    }
                  }}
                type="primary"> {open} {arrows}</Link>,
                ]}>
                  <Row gutter={16}>
                    <Col span={5}>

                      <img src={`/static/media/icons/${topic.data.iconname[0].text}.png`} style={style}/> 
                 
                    </Col>
                    <Col span={14}>
                      <Link 
                      to={{
                        pathname: `/questions/${topic.id}/${uid}`,
                        state: {
                          topic: topic,
                          topicindex: topicindex,
                          survey: survey
                        }
                      }}
                      class="cardLink">{topic.data.topic_title[0].text}</Link>
                    </Col>
                    <Col span={4}>
                        <Progress 
                        width={60}
                        type="circle"
                        strokeColor={{
                          '0%': '#108ee9',
                          '100%': '#87d068',
                        }}
                        percent={topicpercentage}
                      />
                    </Col>
                  </Row>
               
                </Card>

              </Col>
          )
          })
          
          if(survey.sourcesCompleted){
            surveyPercentageCompleted = (parseInt((100 / 8) * survey.sourcesCompleted.length))
          }

          
          if(surveyPercentageCompleted > 95){
            surveyPercentageCompleted = 100
            displayLinkPrio = 'block'
          }
        

      }

      return (
          <>
            
            <Row>
                <Col span={20}>
                  <Title><Link to="/home" class="backlink"><Icon type="double-left" /></Link> <Icon type="read" /> {page.data.title[0].text}</Title>
                </Col>
                <Col span={4}>
                  <Link 
                    to={{
                      pathname: `/priority/${survey.randomstring}`,
                      state: {
                        survey: survey
                      }
                    }} 
                  
                  style={{color:'white', float:'right', display: displayLinkPrio}} 
                  size="large" class="ant-btn ant-btn-primary ant-btn-lg">

                  <Icon type="check-circle" /> <Translate id='survey.prio' />
                  </Link>
                </Col>
            </Row>

          <Row gutter={16}>
            <Col span={24}>
              <span class="pathPartName">För:</span> <span class="pathPartTitle">{survey.name}</span>
              <br/><br/>
            </Col>
          </Row>

            <Row gutter={16}>
              <Col span={20}>
                <Card>
                  <p>{page.data.description[0].text}</p>
                </Card>
                
              </Col>
              <Col span={4} style={{textAlign:"center"}}>
                <Card>
                  <Progress
                      width={75}
                      type="circle"
                      strokeColor={{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                      }}
                      percent={surveyPercentageCompleted}
                    />
                  </Card>
              </Col>
            </Row>
            <Row gutter={16} type="flex" justify="start">
              {
                this.state.topics && 
                  <>{list}</>
              }
            </Row>
            <Row>
              
                <Col span={24}>
                  <Link 
                  to={{
                    pathname: `/priority/${survey.randomstring}`,
                    state: {
                      survey: survey
                    }
                  }}
                  
                  style={{color:'white', float:'right', display: displayLinkPrio}} 
                  size="large" class="ant-btn ant-btn-primary ant-btn-lg">

                  <Icon type="check-circle" /> <Translate id='survey.prio' />
                  </Link>
                </Col>
            </Row>
          </>
      )
    } 
    else if (this.state.notFound) {
      return <NotFound />;
    }

    return (
        <>
          <Spin /> 
          <Skeleton />
        </>
      )
  }
}


const condition = authUser => !!authUser;

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  surveys: Object.keys(state.surveyState.surveys || {}).map(
    key => ({
      ...state.surveyState.surveys[key],
      uid: key,
    })
  ),
  limit: state.surveyState.limit
});

const mapDispatchToProps = dispatch => ({
  onSetSurveys: surveys =>
    dispatch({ type: 'SURVEYS_SET', surveys }),
  onSetSurveysLimit: limit =>
    dispatch({ type: 'SURVEYS_LIMIT_SET', limit }),
});


export default compose(
  withFirebase,
  withLocalize,
  withAuthorization(condition),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(Survey);
