import React from 'react';

import { compose } from 'recompose';
import { withFirebase } from '../Firebase'
import { withLocalize } from "react-localize-redux"
import { withAuthorization } from '../Session'


import NotFound from '../NotFound';
import { Row, Col, Spin, Typography, Card, Icon, Steps, Button, Input} from 'antd';
import { Link } from 'react-router-dom';
import QuestionItem from './QuestionItem'

const Prismic = require('prismic-javascript');
const { Title } = Typography;
const style={
  float: 'right',
  width: '60px',
  height: '60px'
}


class Question extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      notFound: false,
      questions: null,
      notFoundQuestions: null,
      current: 0,
    }

    if (props.prismicCtx) {
      this.fetchPage(props);
      this.fetchQuestions(props);
    }

    this.onChange = this.onChange.bind(this);
    
  }

  onChange = current => {
    // console.log('onChange:', current);
    this.setState({ current });
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  componentDidUpdate(prevProps) {
    if (this.props.prismicCtx) {
      this.props.prismicCtx.toolbar();
    }
    
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
      this.fetchQuestions(this.props);
    }

    if(prevProps.language !== this.props.language){
      // console.log("update")
      this.fetchQuestions(this.props);
      this.fetchPage(this.props);
    }

  }

  fetchPage(props) {
    let langsel = 'sv-se'
    if(props.language == "en"){
      langsel = 'en-gb'
    }
    if (props.prismicCtx) {
      let that = this
      return props.prismicCtx.api.getByUID('page', 'questionpage', { lang : langsel }).then(function(page, err){
          if (page) {
            that.setState({ page: page });
          } else {
            that.setState({ notFound: !page });
          }
      })
    }
    return null;
  }

  fetchQuestions(props) {

    let langsel = 'sv-se'
    console.log("props.language", props.language)
    if(props.language == "en"){
      langsel = 'en-gb'
    }

    if (props.prismicCtx) {
     
      props.prismicCtx.api.query(
        Prismic.Predicates.at('my.question.belongstosource', props.match.params.uid), 
        { lang : langsel, orderings : '[my.question.sortering]' }, (err, questions) => {
          console.log("questions", questions)
        if (questions) {
          this.setState({ questions });
        } else {
          this.setState({ notFoundQuestions: !questions });
        }
      });

    }
    return null;
  }

  goBack(){
    this.props.history.push("/survey/"+ this.props.match.params.pid);
  }

  render() {

    const { page } = this.state;
    const { current } = this.state
    const { topic, topicindex, survey } = this.props.location.state


    if (this.state.questions && page)
    {
      // console.log("topic",topic)

      let steps = this.state.questions.results;
      
      let answers 
      if(survey.sourcesAnswers){
        answers = survey.sourcesAnswers[topicindex]
      }
 
      return (
        <>

          <Row gutter={16}>
            <Col span={24}>
              <Title><Link to={`/survey/${this.props.match.params.pid}`} class="backlink"><Icon type="double-left" /></Link>    <img src={`/static/media/icons/${topic.data.iconname[0].text}.png`} style={style}/>  {topic.data.topic_title[0].text}</Title>
            </Col>
        
          </Row>  

          <Row gutter={16}>
            <Col span={24}>
              <span class="pathPartName"> {this.props.language === 'en' ? 'For' : 'För'}:</span> <Link to={`/survey/${survey.randomstring}`} ><span class="pathPartTitle">{survey.name}</span></Link>
              <br/><br/>
            </Col>
          </Row>

          <Row gutter={16}>
              <Col span={24}>
                <Card>
                  <p>{topic.data.topic_description[0].text}</p>
                </Card>
              </Col>
            </Row>

        <Row>
          <Col span={24}>
            <Card>

              <QuestionItem 
                language={this.props.language}
                questions={this.state.questions.results} 
                survey={survey} topicindex={topicindex} 
                updateSteps={this.onChange} 
                answers={answers}/>

              <div className="steps-action">

                {current < steps.length - 1 && (
                  <Button size="large" type="primary" onClick={() => this.next()}>
                    {this.props.language === 'en' ? 'Next' : 'Nästa'}  <Icon type="double-right" /> 
                  </Button>
                )}

                {current === steps.length - 1 && (
                  <Link style={{color:'white'}} to={`/survey/${this.props.match.params.pid}`} size="large" class="ant-btn ant-btn-primary ant-btn-lg" >
                    <Icon type="check-circle" />{this.props.language === 'en' ? 'Save and on to the next source' : 'Spara och gå vidare till nästa källa'} 
                    
                  </Link>
                )}
              
              </div>

            </Card>

          </Col>
          
        </Row>
      </>

      );
    } 
    else if (this.state.notFound) {
      return <NotFound />;
    }

    return  <Spin color="primary" />;

  }
}

const condition = authUser => !!authUser;

export default compose(
  withLocalize,
  withFirebase,
  withAuthorization(condition)

)(Question);

