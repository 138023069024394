import React, { Component } from 'react';
import { Row, Col, Icon, Divider, Steps, Radio, Input} from 'antd';
import { withFirebase } from '../Firebase';

const { TextArea } = Input;
const { Step } = Steps;

class QuestionItem extends Component {

    constructor(props) {

        super(props);

        this.state = {
            current: 0,
            percentagecompleted: 0
        }

    }
    componentDidMount(){

        let currentAnswers 
        if(this.props.survey.sourcesAnswers){
            if(this.props.survey.sourcesAnswers[this.props.topicindex]){
                currentAnswers = this.props.survey.sourcesAnswers[this.props.topicindex]["topic_"+ this.props.topicindex +"_total"]
            }
        }
        this.setState({ current: (currentAnswers+1) });
        this.props.updateSteps((currentAnswers))

    }

    handleChange(questionindex, topicindex, surveyuid, event){
        this.props.firebase.survey(surveyuid +"/sourcesAnswers/"+ topicindex).update({
            ["topic_"+ topicindex +"_comment_"+ questionindex]: event.target.value
        })
    }

    handleClick(questionindex, indexinner, surveyuid, alt, questionstotal, topicindex, questionLabel, questionId, questionRapportText, showInRapport){

        let percentagecompleted = (parseInt((100/questionstotal)) * (questionindex+1))

        this.props.firebase.survey(surveyuid +"/sourcesAnswers/"+ topicindex).update({
            ["topic_"+ topicindex +"_questionlabel_"+ questionindex]: questionLabel,
            ["topic_"+ topicindex +"_question_"+ questionindex]: alt,
            ["topic_"+ topicindex +"_value_"+ questionindex]: (indexinner + 1),
            ["topic_"+ topicindex +"_questionid_"+ questionindex]: questionId,
            ["topic_"+ topicindex +"_questionrapporttext_"+ questionindex]: questionRapportText,
            ["topic_"+ topicindex +"_questionshowinrapport_"+ questionindex]: showInRapport,            
        })

        if(percentagecompleted > 89){
            percentagecompleted = 100
        }

        this.props.firebase.survey(surveyuid +"/sourcesPercentage/").update({
            [topicindex] : percentagecompleted
        })

        if(percentagecompleted==100){
            this.props.firebase.survey(surveyuid +"/sourcesCompleted/").update({
                [topicindex]: true
            })
        }

        const current = questionindex;
        this.setState({ current });

        this.props.firebase.survey(surveyuid +"/sourcesAnswers/"+ topicindex).update({
            ["topic_"+ topicindex +"_total"]: current,
        })

        this.props.updateSteps(current)

    }

    render() {
        
        const { current } = this.state
        let that = this;
        const { survey, topicindex, answers, questions } = this.props
        const label = this.props.language === 'en' ? 'Guidance / assessment levels' : 'Vägledning / bedömningsnivåer'
        const label2 = this.props.language === 'en' ? 'Comments' : 'Kommentarer'
        

        if(questions){

            let defaultChecked = null
            return (

                <Steps direction="vertical" current={current} label={label}>

                    {questions.map(function(question, questionindex) {

                        // console.log("questionquestion", question)                        
                        let comment
                        let questionLabel = question.data.question[0].text

                        let questionRapportText = ""
                        if(question.data.rapport_text.length > 0){
                            questionRapportText = question.data.rapport_text[0].text
                        }

                        let showInRapport = question.data.kommer_med_i_rapport

                        if(answers){
                            defaultChecked = answers["topic_"+ topicindex +"_question_"+ questionindex]
                            comment = answers["topic_"+ topicindex +"_comment_"+ questionindex]
                        }
            
                        return (
                    
                        <Step 
                                
                            description={
                            <>
                            <Row gutter={16}>

                                <Col span={12}>
                                <h4>{questionLabel}</h4>
                                <i>
                                    {question.data.prerequisitesandactions  > 0 && question.data.prerequisitesandactions[0].text}
                                    <br/>
                                    {question.data.sourcereferens > 0 && question.data.sourcereferens[0].text}
                                </i>
                                <Radio.Group buttonStyle="solid">
                                    {question.data.alternatives && question.data.alternatives.map(function(item) {
                                    return (
                                        <>{item.alternative_description && (<><p><Icon type="info-circle" /> {item.alternative_description}</p></>)}</>
                                    )
                                    })}
                                </Radio.Group>
                                </Col>
                                <Col span={12}>

                                <p class="small">{label}</p>

                                <Radio.Group buttonStyle="solid" size="large" defaultValue={defaultChecked}>

                                    {question.data.alternatives && question.data.alternatives.map(function(item, buttonindex) {

                                    return (
                                        <>
                                        <Radio.Button checked={defaultChecked} 
                                            value={item.alternative} 
                                            onClick={(e) => that.handleClick(questionindex, buttonindex, survey.uid, item.alternative, questions.length, topicindex, questionLabel, question.id, questionRapportText, showInRapport)}>
                                            {item.alternative}
                                        </Radio.Button>
                                        </> )
                                    })}
                                </Radio.Group>

                                <Divider/>

                                <TextArea placeholder={label2}
                                    onChange={(e) => that.handleChange(questionindex, topicindex, survey.uid, e)}>{comment}</TextArea> 

                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                
                                </Col>
                            </Row>
                            <Divider/>
                            </>
                        }
                        /> )

                    })}
                    
                </Steps>

            );
        }
        else{
            return null;
        }
    }
}

export default withFirebase(QuestionItem);
