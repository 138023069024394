import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { Form, Input, Button, Row, Col, Icon, Card } from 'antd';

const PasswordForgetPage = () => (
  <div>
    <h1>Glömt lösenord</h1>
    <p>Har du ett konto i Blastic så kommer vi skicka ut ett email med återställningslänk.</p>
    <PasswordForgetForm />

  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
  okMess: ''
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.setState({okMess: "Om ditt email är registrerad så skickar vi nu ut en länk för dig att återställa lösenordet med."})
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error, okMess } = this.state;

    const isInvalid = email === '';

    return (
      <Card type="flex" style={{ width: 600 }}>
      <form onSubmit={this.onSubmit}>
        <input
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <button disabled={isInvalid} type="submit">
          Skicka
        </button>

        {error && <p>{error.message}</p>}
        {okMess && <p>{okMess}</p>}
        <br></br><br></br>
              <a href="/">Tillbaka till inlogg</a>

      </form></Card>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Glömt lösenord?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
