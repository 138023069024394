import React from 'react'

import { compose } from 'recompose';
import { withFirebase } from '../Firebase'
import { withLocalize } from "react-localize-redux"
import { withAuthorization } from '../Session'

import NotFound from '../NotFound';
import {  Spinner,   } from 'reactstrap';
import { Row, Col, Card, Icon , Typography, Button, Divider} from 'antd'
import { Translate } from "react-localize-redux"

const { Title } = Typography
const Prismic = require('prismic-javascript')


class About extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      action: null,
      notFound: false,
      actionsections: null,
      topic: null
    }

    if (props.prismicCtx) {
      this.fetchTopics(props)
      this.fetchPage(props)
    }
    
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.prismicCtx) {
      this.fetchTopics(this.props)
      this.fetchPage(this.props)
    }
  }

  fetchTopics(props) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.query(Prismic.Predicates.at('document.type', 'actionsection'), { orderings : '[my.actionsections.orderby desc]' }, (err, actionsections) => {
        if (actionsections) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ actionsections });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFoundTopic: !actionsections });
        }
      });
    }
    return null;
  }

  handleClick(actionsectionid, that){

    if (that.props.prismicCtx) {

      // We are using the function to get a document by its uid
      return that.props.prismicCtx.api.query([
        Prismic.Predicates.at('document.type', 'action'),
        Prismic.Predicates.at('my.action.actionsection', actionsectionid)	
      ]).then(function(action) {
        that.setState({ action: action });
      });
       
    }
    return null
  }

  fetchPage(props) {

    let langsel = 'sv-se'
    if(props.language == "en"){
      langsel = 'en-gb'
    }
    
    if (props.prismicCtx) {
      let that = this
      return props.prismicCtx.api.getByUID('page', 'knowledge', { lang : langsel }).then(function(page, err){
          if (page) {
            that.setState({ page: page });
          } else {
            that.setState({ notFound: !page });
          }
      })
    }

    return null;

  }


  render() {

    const {actionsections, page, action} = this.state

    if (actionsections && page) {

      let that = this

      // console.log("action", action)

      return (

        <>
        <Row gutter={16}>
          <Col span={24}>
            <Title><Icon type="read" /> <Translate id='knowledge.title' /></Title>
          </Col>
        </Row>

        <Row gutter={16}>
            <Col span={24}>
              <Card>
              <p>{page.data.description[0].text}</p>
              </Card>
              
            </Col>
        </Row>

        <Row gutter={16}>
            <Col span={6}>
              <Card>
                {actionsections.results.map(function(actionsection) {
                    return (
                      <Button type="link" className="menuLink"  onClick={(e) => that.handleClick(actionsection.id, that)}><Icon type="read" /> {actionsection.data.name[0].text}</Button>
                    )
                  })
                }
              </Card>
              
            </Col>
            <Col span={18}>
              <Card>

                <h2>{action && action.results[0].data.title[0].text}</h2>
                <h3>{action && action.results[0].data.condition[0].text}</h3>
              {action && action.results[0].data.longdescription &&(<><Divider/><h3>Detaljerad information</h3>{action.results[0].data.desc[0].text}<Divider/>{action.results[0].data.longdescription[0].text}</>)}

              
                {/* <h1>{topic && topic.data.subtitle[0].text}</h1>
                <h2>{topic && topic.data.title[0].text}</h2>
                {topic && topic.data.title &&(<><h3>Åtgärdsförslag</h3></>)}
                {topic && topic.data.desc.map(function(top, topindex) {
                    return (
                      <p><Icon type="bulb" /> {top.text}</p>
                    )
                  })
                }
                
                {topic && topic.data.longdescription &&(<><Divider/><h3>Detaljerad information</h3></>)}
                {topic && topic.data.longdescription.map(function(top, topindex) {
                    return (
                      <p>{top.text}</p>
                    )
                  })
                } */}
              
              </Card>
            </Col>
        </Row>

        
      </>

      );
    } 
    else if (this.state.notFound) {
      return <NotFound />;
    }

    return  <Spinner color="primary" />;

  }
}

const condition = authUser => !!authUser;

export default compose(
  withLocalize,
  withFirebase,
  withAuthorization(condition)

)(About);
