import React from 'react';
import { BrowserRouter as Router, Route, BrowserRouter, Switch} from 'react-router-dom';
import styles from './app.module.css'; 
import Navigation from '../Navigation';
import LandingPage from '../Landing';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import ContentPage from '../ContentPage';
import CheckList from '../CheckList'
import Question from '../Question'
import FooterComponent from '../Footer';
import Survey from '../Survey';
import Priority from '../Priority'
import Action from '../Action'
import Raport from '../Raport'
import RaportPdf from '../RaportPdf'
import Knowledge from '../Knowledge'
import SignUp from '../SignUp'
import About from '../About'
import PasswordForget from '../PasswordForget'
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import 'whatwg-fetch';
import Prismic from 'prismic-javascript';
import PrismicConfig from '../../prismic-configuration';
import { Layout } from 'antd';
import logo from './images/logo.png';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import globalTranslations from "../translations/global.json";


const { Header, Footer, Content } = Layout;


class App extends React.Component {

  constructor(props) {

    super(props);
    
    this.state = { 
      prismicCtx: null,
      language: null
    };

    this.props.initialize({
      languages: [
        { name: "Svenska", code: "sv" },
        { name: "English", code: "en" }
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage: "sv"
      }
    });

    this.buildContext().then((prismicCtx) => {
      this.setState({ prismicCtx });
    }).catch((e) => {
      console.error(`Cannot contact the API, check your prismic configuration:\n${e}`);
    });

    this.setLanguage = this.setLanguage.bind(this)

  }

  refreshToolbar() {
    const maybeCurrentExperiment = this.api.currentExperiment();
    if (maybeCurrentExperiment) {
      window.PrismicToolbar.startExperiment(maybeCurrentExperiment.googleId());
    }
    window.PrismicToolbar.setup(PrismicConfig.apiEndpoint);
  }

  buildContext() {
    const accessToken = PrismicConfig.accessToken;
    return Prismic.api(PrismicConfig.apiEndpoint, { accessToken }).then(api => ({
      api,
      endpoint: PrismicConfig.apiEndpoint,
      accessToken,
      linkResolver: PrismicConfig.linkResolver,
      toolbar: this.refreshToolbar,
    }));
  }

  setLanguage = (lang) => {
    this.setState({language: lang}) 
  }

  render() {

    const { language } = this.state

    // console.log("app language", language)

    return <BrowserRouter>
        <div>
          <Layout className="layout">

            <Header className={styles.headerBg}>

              <a href="/home"><img src={logo} alt="Logo" style={{paddingTop: '8px', paddingBottom: '40px', height: '100px'}} class="no-print"/></a>

              <Navigation setLanguage={this.setLanguage}/> 

              
            </Header>

            <Content style={{ padding: '0px 50px'}}>

              <div style={{ padding: 24, minHeight: 280 }}>
                <Switch>                  
        
                  <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForget} breadcrumbName="PasswordForget"/>
                  <Route exact path={ROUTES.LANDING} component={LandingPage} breadcrumbName="Home"/>
                  <Route exact path="/signin" component={LandingPage} breadcrumbName="Home"/>
                  <Route exact path="/signup" component={SignUp} breadcrumbName="SignUp"/>
                  <Route exact path="/home" render={routeProps => <HomePage {...routeProps} prismicCtx={this.state.prismicCtx} language={language}/>} />
                  <Route exact path="/home/:id" render={routeProps => <HomePage {...routeProps} prismicCtx={this.state.prismicCtx} language={language}/>} />
                  <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
                  <Route exact path={ROUTES.ADMIN} component={AdminPage} />                  
                  <Route exact path="/page/:uid" render={routeProps => <ContentPage {...routeProps} prismicCtx={this.state.prismicCtx} language={language}/>} />
                  <Route exact path="/page/:uid/:subid" render={routeProps => <ContentPage {...routeProps} prismicCtx={this.state.prismicCtx} language={language}/>} />
                  <Route exact path="/checklist/:uid" render={routeProps => <CheckList {...routeProps} prismicCtx={this.state.prismicCtx} language={language}/>} />
                  <Route exact path="/question/:uid" render={routeProps => <Question {...routeProps} prismicCtx={this.state.prismicCtx} language={language}/>} />
                  <Route exact path="/survey/:uid" render={routeProps => <Survey {...routeProps} prismicCtx={this.state.prismicCtx} language={language}/>} />
                  <Route exact path="/questions/:uid/:pid" render={routeProps => <Question {...routeProps} prismicCtx={this.state.prismicCtx} language={language}/>} />
                  <Route exact path="/priority/:uid/" render={routeProps => <Priority {...routeProps} prismicCtx={this.state.prismicCtx} language={language}/>} />
                  <Route exact path="/action/:uid/" render={routeProps => <Action {...routeProps} prismicCtx={this.state.prismicCtx} language={language}/>} />
                  <Route exact path="/raport/:uid/" render={routeProps => <Raport {...routeProps} prismicCtx={this.state.prismicCtx} language={language}/>} />
                  <Route exact path="/raportpdf/:uid/" render={routeProps => <RaportPdf {...routeProps} prismicCtx={this.state.prismicCtx} language={language}/>} />
                  <Route exact path="/knowledge/" render={routeProps => <Knowledge {...routeProps} prismicCtx={this.state.prismicCtx} language={language}/>} />
                  <Route exact path="/about/" render={routeProps => <About {...routeProps} prismicCtx={this.state.prismicCtx} language={language}/>} />
              </Switch>
              </div>

            </Content>
            <Footer  style={{ textAlign: 'center' }}>
              <FooterComponent/>
            </Footer>
          </Layout>
        </div>
      </BrowserRouter>  
  }
}


export default compose(
  withLocalize,
  withAuthentication
)(App);


