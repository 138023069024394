import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import styles from './signin.module.css'; 
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { Form, Input, Button, Row, Col, Icon, Card } from 'antd';
import logo from './logo.png';
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import randomstring from 'randomstring';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class NormalLoginForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;
    const rndString = randomstring.generate(10);
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME +"/"+ rndString);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();

  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {

    const { email, password, error } = this.state;


    return (
      <Translate>
        {({ translate }) => (
        <Row>
          
          <Col span={12} offset={6} type="flex" justify="center" align="middle">
            <img src={logo} alt="Logo" style={{paddingBottom: '40px'}}/>

           

          </Col>

          <Col span={12} offset={6} type="flex" justify="center" align="middle">
          <Card type="flex" style={{ width: 600 }}>
          {translate('signin.welcome')}
          {/* <p>Välkommen till BLASTIC <br></br> Håll Sverige Rents verktyg för att kartlägga källor och spridningsvägar för marint skräp. Är du intresserad av att använda verktyget? <br></br><br></br>Kontakta Stefan Hållberg på stefan.hallberg@hsr.se eller 073-699 04 16.</p> */}

              <Form onSubmit={this.onSubmit} className={styles.loginForm}>
              <Form.Item>
                <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                name="email"
                value={email}
                onChange={this.onChange}
                type="text"
                placeholder={translate('signin.email')}
                size="large"
                />
              </Form.Item>

              <Form.Item>
                <Input
                name="password"
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  value={password}
                  onChange={this.onChange}
                  type="password"
                  placeholder={translate('signin.password')}
                  size="large"
                />
              </Form.Item>


              <Form.Item>
                <Button type="primary" size="large" htmlType="submit" className={styles.loginFormButton}>
                <Icon type="login" /> {translate('signin.login')}
                </Button>
              </Form.Item>
              {error && <p>{error.message}</p>}
              <br></br>
              <a href="/pw-forget">{translate('signin.forgot')}?</a>
            </Form>

            </Card>
          </Col>
  
        </Row>
        )}
      </Translate>
    );
  }
}


const WrappedNormalLoginForm = compose(
  withLocalize,
  withRouter,
  withFirebase,
)(NormalLoginForm);


export default WrappedNormalLoginForm;

