const INITIAL_STATE = {
  surveys: null,
  limit: 20,
};

const applySetSurveys = (state, action) => ({
  ...state,
  surveys: action.surveys,
});

const applySetSurveysLimit = (state, action) => ({
  ...state,
  limit: action.limit,
});

function surveyReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SURVEYS_SET': {
      return applySetSurveys(state, action);
    }
    case 'SRUVEYS_LIMIT_SET': {
      return applySetSurveysLimit(state, action);
    }
    default:
      return state;
  }
}

export default surveyReducer;
