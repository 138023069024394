import React from 'react';
import { compose } from 'recompose';
import { withAuthorization } from '../Session';
import NotFound from '../NotFound';
import { Row, Col, Spin, Skeleton, Icon, Progress, Button, notification, Typography, Modal,  Form, Input, Card, Checkbox} from 'antd';
import { withFirebase } from '../Firebase';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import randomstring from 'randomstring';
import PrismicReact from 'prismic-reactjs'
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
// import Moment from 'react-moment';
// import 'moment-timezone';

const { TextArea } = Input;
const { Title } = Typography;
const style={fontSize:'20px'};

const doneCreatingNotification = (type, message = 'Kartläggning skapad', description = 'Klicka "Öppna" för att starta arbetet med den.') => {
  notification[type]({
    message: message,
    description: description
  });
};


const CollectionEditForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="Editera kartläggning"
          okText="Spara"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            <Form.Item label="Title">
              {getFieldDecorator('title', {
                rules: [{ required: true, message: 'Please input the title of collection!' }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Description">
              {getFieldDecorator('description')(<TextArea  />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  },
);

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form, onWeAreGroupChange } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal centered
          visible={visible}
          title={<Translate id="home.create" />}
          okText={<Translate id="home.createbutton" />}
          cancelText={<Translate id='modalcreate.cancelText' />}
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            
            <Form.Item label={<Translate id='modalcreate.title' />}>
              {getFieldDecorator('title', {
                rules: [{ required: true, message: <Translate id='modalcreate.titleMissing' /> }],
              })(<Input />)}
            </Form.Item>
            
            <Form.Item label={<Translate id='modalcreate.description' />}>
              {getFieldDecorator('description', {
                rules: [{ required: false, message: <Translate id='modalcreate.description' /> }],
              })(<TextArea type="textarea" style={{height :'160px'}} />)}
            </Form.Item>
            
            <Form.Item label={<Translate id='modalcreate.workgroupnames' />}>
              {getFieldDecorator('workgroupnames', {
                rules: [{ required: true, message: <Translate id='modalcreate.workgroupnames' /> }],
              })(<TextArea type="textarea" style={{height :'160px'}} />)}
            </Form.Item>

            <Form.Item label={<Translate id='modalcreate.workgroup' />}>
            {getFieldDecorator('grouping', {
                rules: [{ required: true, message: <Translate id='modalcreate.workgroupError' /> }],
              })(<Checkbox
              onChange={onWeAreGroupChange}
            >
              <Translate id='modalcreate.workgroupDone' />
            </Checkbox>)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  },
);

class HomePage extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      notFound: false,
      topic: null,
      notFoundTopic: null,
      loadingSurveys: false,
      visible: false,
      visibleEdit: false,
      page: null,
      surveys: null
    }

    if (props.prismicCtx) {
      this.fetchPage(props);
    }


  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showModalEdit = () => {
    this.setState({
      visibleEdit: true,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
    this.setState({ visibleEdit: false });
  }


  onWeAreGroupChange= () => {

    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      // console.log("onWeAreGroupChange", values.grouping)
    });
    
  }

  handlEdit = () => {
    
    const { form } = this.formRef.props;

    form.validateFields((err, values) => {

      if (err) {
        return;
      }

      form.resetFields();

      const rndString = randomstring.generate(5);

      this.props.firebase.surveys().push({
        name: values.title,
        description: values.description,
        workgroup: values.workgroupnames,
        owner: this.props.authUser.uid,
        createdAt: this.props.firebase.serverValue.TIMESTAMP,
        percentageCompleted: 0,
        randomstring: rndString,
        uniqueid: this.props.authUser.uid +"_"+ rndString
      });
  
      this.setState({ text: '' });
      this.setState({ visible: false });

    });
  };

  handleCreate = () => {
    
    const { form } = this.formRef.props;

    form.validateFields((err, values) => {

      if (err) {
        return;
      }

      form.resetFields();
      
      var date = new Date().getDate(); //Current Date
      var month = new Date().getMonth() + 1; //Current Month
      var year = new Date().getFullYear(); //Current Year

      const rndString = randomstring.generate(5);

      this.props.firebase.surveys().push({
        name: values.title,
        description: values.description,
        owner: this.props.authUser.uid,
        createdAt: this.props.firebase.serverValue.TIMESTAMP,
        randomstring: rndString,
        uniqueid: this.props.authUser.uid +"_"+ rndString,
        hasRapport: false,
        hasPrio: false,
        createdDate: year + '-' + month + '-' + date,
        doneWithFirst: false,
        belongsToState: this.props.authUser.secondCity,
        workgroupnames: values.workgroupnames
      });
  
      this.setState({ text: '' })
      this.setState({ visible: false })
      
      if(this.props.language == "en"){
        doneCreatingNotification('success', 'Survey created successfully!', 'You can now add questions to your survey.');
      }
      else{
        doneCreatingNotification('success')
      }
      

    });
  };

  onListenForSurveys = () => {
    let results = null
    this.props.firebase
      .surveys()
      .orderByChild("belongsToState")
      .equalTo(this.props.authUser.secondCity)
      .limitToLast(100)
      .on('value', snapshot => {

        results = snapshot.val();

        console.log("results", results)

        if(results){
          const list = Object.keys(results).map(key => ({
            ...results[key],
            uid: key,
          }))
          .sort((a,b) =>  { return a.createdAt - b.createdAt });
          
          //this.stry.onSetSurveys(list);
          this.setState({ surveys: list });
        }
        else{
          this.setState({ notFound: true });
        }

      });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  componentDidUpdate(prevProps) {
    
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
    }

    if(prevProps.language !== this.props.language){
      this.fetchPage(this.props);
    }
  }

  componentWillUnmount() {
    this.props.firebase.surveys().off();
    notification.destroy()
  }

  componentDidMount() {
   
    this.onListenForSurveys();
  }


  fetchPage(props) {

    let langsel = 'sv-se'
    if(props.language == "en"){
      langsel = 'en-gb'
    }
    
    if (props.prismicCtx) {
      let that = this
      return props.prismicCtx.api.getByUID('page', 'welcome', { lang : langsel }).then(function(page, err){
          if (page) {
            that.setState({ page: page });
          } else {
            that.setState({ notFound: !page });
          }
      })
    }

    return null;

  }

  render() {

    // let surveys = null
    // surveys = this.props.surveys;
    const { page, surveys } = this.state;

    if (surveys && page) {

      return (  
        <>
        
        <Row gutter={16}>
          <Col span={12}>
            <Title><Icon type="home" /> {page.data.title[0].text}</Title>
          </Col>
          <Col span={12}>
          <Button style={{float: 'right'}} type="primary" size="large" onClick={this.showModal}><Icon type="plus"/> <Translate id='home.buttons.create' /></Button>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Card>
              {PrismicReact.RichText.render(page.data.description, this.props.prismicCtx.linkResolver)}
            
            </Card>
            
          </Col>
        </Row>
        <Row gutter={16}>
  
          {surveys.map(function(item){

            let percentagecompleted = 0

            // console.log("item", item)

            if(item.sourcesCompleted){
              percentagecompleted = (parseInt((100 / 8) * item.sourcesCompleted.length)) 
            }
            
            let pdf = null;
            let prio = null;
          
            {item.hasRapport && (pdf = <Link 
              to={{
                pathname: `/raport/${item.randomstring}`,
                state: {
                  survey: item
                }
              }} 
            type="default"><Icon type="file-pdf" /> <Translate id='home.table.links.raport' /></Link>)}

            {item.hasPrio && (prio = <Link 
              to={{
                pathname: `/priority/${item.randomstring}`,
                state: {
                  survey: item
                }
              }} 
              type="default"><Icon type="bars" /> <Translate id='home.table.links.prio' /></Link>)}

            return <Col span={8}>
              <Card bordered={false} 
              hoverable="true"
              actions={[
                pdf,
                prio,
                <Link to={`/survey/${item.randomstring}`} type="default"> <Translate id="home.edit" /></Link>,
                <Link to={`/survey/${item.randomstring}`} type="primary" class="primary"><Translate id="home.open" /> </Link>,
              ]}>
              <Row gutter={16}>
                <Col span={19}>
                  <h3>{item.name}</h3>
                  <p class="date"><Translate id="home.created" /> {item.createdDate}</p>
                  <p class="ingress">{item.description}</p>
                </Col>
                <Col span={5}>
                  <Progress style={{float: 'right'}}
                    width={80}
                    type="circle"
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }}
                    percent={percentagecompleted}
                  />
                </Col>
              </Row>

              </Card>
            </Col>
          })}

        </Row>

        <CollectionEditForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visibleEdit}
          onCancel={this.handleCancelEdit}
          onCreate={() =>
            this.handleEdit(this.props.authUser)
          }
        />  

        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={() =>
            this.handleCreate(this.props.authUser)
          }
          onWeAreGroupChange={() =>
            this.onWeAreGroupChange(this.props.authUser)
          }
        /> 

        </>
      )
    } 
    else if (this.state.notFound) {
      return(<><Row gutter={16}>
          <Col span={12}>
            <Title><Icon type="home" /> {page.data.title[0].text}</Title>
          </Col>
          <Col span={12}>
          <Button style={{float: 'right'}} type="primary" size="large" onClick={this.showModal}><Icon type="plus"/> <Translate id='home.buttons.create' /></Button>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Card>
              {PrismicReact.RichText.render(page.data.description, this.props.prismicCtx.linkResolver)}
            
            </Card>
            
          </Col>
        </Row>
        
        <CollectionEditForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visibleEdit}
          onCancel={this.handleCancelEdit}
          onCreate={() =>
            this.handleEdit(this.props.authUser)
          }
        />  

        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={() =>
            this.handleCreate(this.props.authUser)
          }
          onWeAreGroupChange={() =>
            this.onWeAreGroupChange(this.props.authUser)
          }
        /> 
        </>)
    }

    return (
        <>
          <Spin /> 
          <Skeleton />
        </>
      )
  }
}


const condition = authUser => !!authUser;

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  // surveys: Object.keys(state.surveyState.surveys || {}).map(
  //   key => ({
  //     ...state.surveyState.surveys[key],
  //     uid: key,
  //   })
  // ),
  //limit: state.surveyState.limit
});

const mapDispatchToProps = dispatch => ({
  // onSetSurveys: surveys =>
  //   dispatch({ type: 'SURVEYS_SET', surveys }),
  // onSetSurveysLimit: limit =>
  //   dispatch({ type: 'SURVEYS_LIMIT_SET', limit }),
});


export default compose(
  withLocalize,
  withFirebase,
  withAuthorization(condition),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(HomePage);
