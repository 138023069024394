import React, { Component } from 'react';
import { Row, Col, Card, Switch, Icon} from 'antd';
import Ranking from '../Ranking'
import ActionItem from './ActionItem'
import { withFirebase } from '../Firebase';


class ActionList extends Component {

    constructor(props) {
      super(props)
      this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (value, e) => {

      // console.log(value, e)

      this.setState({ [value] : e})
      this.setState({ [value +"_display"] : e})

      this.props.firebase.survey(this.props.survey.uid +"/showInRapport/").update({
        [value]: e
      })

    }

    render() 
    {
      const {survey} = this.props

      const panels = []
      
      if(survey){
        
        const that = this
      
        survey.sourcesAnswers.map(function(question, questionindex) {

          let total = parseInt(question["topic_"+ questionindex +"_total"]) + 1
          
          for (var i = 0; i < total; i++) {

            let value = question["topic_"+ questionindex +"_value_"+ i]
            let showInRapportHsr = question["topic_"+ questionindex +"_questionshowinrapport_"+ i]

            if(value === 3 && questionindex > 0 && showInRapportHsr){
            
              let uniqueid = questionindex +"_"+ i

              let header = question["topic_"+ questionindex +"_questionlabel_"+ i]
              let answer = question["topic_"+ questionindex +"_question_"+ i]
              let comment = question["topic_"+ questionindex +"_comment_"+ i]
              let questionid = question["topic_"+ questionindex +"_questionid_"+ i]
              
      
              let rowTotal = survey.priorityRank[uniqueid]

              let prioComments
              if(survey["questionPrioComments"]){
                prioComments = survey.questionPrioComments[uniqueid]
              }
              //let prioComments = survey.questionPrioComments[uniqueid]

              let thisOnOff = ''
              let thisOnOffComment = ''
              let thisOnOffPrioComment = ''
              let disabledClass = ''

              if(that.state!==null){

                if(that.state[uniqueid+"_question"]==false){
                  thisOnOff = 'none'
                }

                if(that.state[uniqueid +"_comment"]==false){
                  thisOnOffComment = 'none'
                }

                if(that.state[uniqueid +"_commentPrio"]==false){
                  thisOnOffPrioComment = 'none'
                }
              }
             
              let showInRapport
              let showInRapportComment  
              let showInRapportCommentPrio
              let showInRapportActions

              if(survey["showInRapport"]){
  
                showInRapport = survey.showInRapport[uniqueid +"_question"]
                showInRapportComment = survey.showInRapport[uniqueid +"_comment"]
                showInRapportCommentPrio = survey.showInRapport[uniqueid +"_commentPrio"]
                showInRapportActions = survey.showInRapport[uniqueid +"_actions"]
              }

              if(showInRapport === undefined) {
                showInRapport = true
                thisOnOff = ''
              }
              else if (showInRapport === false) {
                showInRapport = false
                thisOnOff = 'none'
              }
              else{
                showInRapport = true
              }

              if(showInRapportComment === undefined ){ //|| showInRapportComment === false) {
                showInRapportComment = true
                thisOnOffComment = ''
              }
              else if (showInRapportComment === false) {
                showInRapportComment = false
                thisOnOffComment = 'none'
              }
              else{
                showInRapportComment = true
              }

              if(showInRapportCommentPrio === undefined){ //|| showInRapportCommentPrio === false) {
                showInRapportCommentPrio = true
                thisOnOffPrioComment = ''
              }
              else if (showInRapportCommentPrio === false) {
                showInRapportCommentPrio = false
                thisOnOffPrioComment = 'none'
              }
              else{
                showInRapportCommentPrio = true
              }

              if(showInRapportActions === undefined) {
                showInRapportActions = true
              }
              else if (showInRapportActions === false) {
                showInRapportActions = false
              }
              else{
                showInRapportActions = true
              }
            
              panels.push(<Row gutter={16} disabled>

                <Col span={24}>
                  
                  <Card hoverable>
    
                    <Row gutter={16}>
                    
                      <Col span={20}>
                        <h5 class={disabledClass}>{header}</h5>
                      </Col>

                      <Col span={4} style={{textAlign: 'right'}}>
                        <Switch 
                        checked={showInRapport} 
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                        onChange={(e) => that.handleChange(uniqueid +"_question", e)} 
                        size="large"/> 
                      </Col>

                      <Col span={12} style={{display: thisOnOff, marginTop: 10, marginBottom: 10}}>
                        <h4>{that.props.language === 'en' ? 'Your answer' : 'Ert svar'}: {answer}</h4>
                      </Col>
                      
                      <Col span={12} style={{display: thisOnOff}} style={{textAlign: 'right'}}>
                        <Ranking total={rowTotal} display={thisOnOff} label={that.props.language === 'en' ? 'Ranking' : 'Rankning utifrån prioritering: '}></Ranking>
                      </Col>

                    </Row>
                  
                    <Row gutter={12} style={{display: thisOnOff}}>
                   

                            {comment && (<>    
                            <Col span={12}>
                              <Row style={{marginTop:20}}>
                                <Col span={22}><h5>{that.props.language === 'en' ? 'Your comments to the answer' : 'Era kommentarer till svaret'}</h5></Col>
                                <Col span={2}><Switch checked={showInRapportComment} onChange={(e) => that.handleChange(uniqueid +"_comment", e)} 
                            checkedChildren={<Icon type="check" />}
                            unCheckedChildren={<Icon type="close" />}
                            size="small"/></Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <p style={{display: thisOnOffComment}}>{comment}</p>
                                </Col>
                              </Row>
                            </Col>
                            </>
                            )}

                            {prioComments && (
                            <>
                            <Col span={12}>
                              <Row style={{marginTop:30}}>
                                <Col span={22}><h5>{that.props.language === 'en' ? 'Your comments from prioritize of the question' : 'Era kommentarer från prioriteringen av frågan'}</h5></Col>
                                <Col span={2}><Switch onChange={(e) => that.handleChange(uniqueid +"_commentPrio", e)} 
                              checkedChildren={<Icon type="check" />}
                              unCheckedChildren={<Icon type="close" />}
                              checked={showInRapportCommentPrio} size="small"/></Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <p style={{display: thisOnOffPrioComment}}>{prioComments.comment}</p>
                                </Col>
                              </Row>
                            </Col>
                            </>
                            
                            )}                                                    
                                        
                      </Row>
                      <Row gutter={2} style={{display: thisOnOff}}>
                        <Col span={24}>
                            <ActionItem prismicCtx={that.props.prismicCtx} uniqueid={uniqueid} questionid={questionid} surveyid={survey.uid} showExtra={true} showInRapportActions={showInRapportActions}/>
                        </Col>
                      </Row>                 

                  </Card>
                      
                </Col>
                
                </Row>) 
              
            }
            
          }
          
        })

        return panels
      }
      else{
        return null
      }
    }
}

export default withFirebase(ActionList);
