import React from 'react'
import { compose } from 'recompose'
import { withFirebase } from '../Firebase'
import { withLocalize } from "react-localize-redux"
import { withAuthorization } from '../Session'
import { Row, Col, Spin, Typography, Card, Icon, Collapse, TreeSelect, Select, Divider } from 'antd'
import { Link } from 'react-router-dom'
import ActionItem from '../Action/ActionItem'

const Prismic = require('prismic-javascript')
const { Title } = Typography

const style={
  width: '100px',
  height: '100px'
}

const styleLogo={
  width: '200px',
  height: '130px'
}

class Raport extends React.Component {

  constructor(props){

    super(props);
    
   

    this.state = {
      doc: null,
      notFound: false,
      questions: null,
      notFoundQuestions: null,
      current: 0,
      value: ['0-0-0'],
    }

    if (props.prismicCtx) {
      //this.fetchPage(props);
      //this.fetchQuestions(props);
    }

  }


  componentDidUpdate(prevProps) {
    if (this.props.prismicCtx) {
      this.props.prismicCtx.toolbar();
    }
    
    // We fetch the page only after it's ready to query the api
    // if (!prevProps.prismicCtx) {
    //   //this.fetchPage(this.props);
    //   // /this.fetchQuestions(this.props);
    // }
    
    console.log("!!PROPS", this.props)

    this.props.firebase.survey(this.props.location.state.survey.uid).update({
      ["hasRapport"] : true
    })

    if(prevProps.language !== this.props.language){
      // console.log("update")
      this.fetchPage(this.props);
    }

  }

  componentDidMount(){
    this.props.firebase.survey(this.props.location.state.survey.uid).update({
      ["hasRapport"] : true
    })
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getByUID('page', props.match.params.uid, { lang : this.props.language }, (err, doc) => {
        if (doc) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }

  // fetchQuestions(props) {

  //   if (props.prismicCtx) {
  //     // We are using the function to get a document by its uid
  //     return props.prismicCtx.api.query(
  //       Prismic.Predicates.at('my.question.belongstosource', props.match.params.uid), 
  //       { orderings : '[my.question.question]' }, (err, questions) => {
  //       if (questions) {
  //         // We put the retrieved content in the state as a doc variable
  //         this.setState({ questions });
  //       } else {
  //         // We changed the state to display error not found if no matched doc
  //         this.setState({ notFoundQuestions: !questions });
  //       }
  //     });
  //   }
  //   return null;
  // }

  printMe(){
    window.print()
  }

  goBack(){
    this.props.history.push("/survey/"+ this.props.match.params.pid);
  }

  render() {

    const { survey } = this.props.location.state
    let prismic = this.props.prismicCtx
    let lang = this.props.language
    const that = this

    const panels = []
    const monthNames = ["Januari", "Februari", "Mars", "April", "Maj", "Juni",
    "July", "Augusti", "September", "OKtober", "November", "December"]
    const d = new Date()

    if(prismic){
      return (
        <>
          <Row gutter={16} class="no-print">

            <Col span={18}>
              <Title>
                <Link to={{
                      pathname: `/action/${survey.randomstring}`,
                      state: {
                        survey: survey
                      }
                    }} class="backlink no-print"><Icon type="double-left" />
                  </Link> 
                  <Icon type="bulb" className={"no-print"}/> <span class="no-print">{this.props.language === 'en' ? 'Report' : 'Rapport'}</span>
              </Title>
            </Col>
            <Col span={6} style={{textAlign: 'right'}}>
              {/* <button onClick={this.printMe} class="no-print ant-btn ant-btn-primary ant-btn-lg"><Icon type="file-pdf"/> Spara som PDF</button> */}
                &nbsp;
              <button onClick={this.printMe} class="no-print ant-btn ant-btn-primary ant-btn-lg"><Icon type="printer" /> {this.props.language === 'en' ? 'Print' : 'Skriv ut'}</button>
              
            </Col>
          </Row>

          <Row gutter={16} class="no-print">
            <Col span={24}>
              <span class="pathPartName no-print">{this.props.language === 'en' ? 'For' : 'För'}:</span> <Link to={`/survey/${survey.randomstring}`} ><span class="no-print pathPartTitle">{survey.name}</span></Link>
              <br/><br/>
            </Col>
          </Row>

            {survey.sourcesAnswers.map(function(question, questionindex) {
              console.log("survey loop question",survey.showInRapport)
          
              let total = parseInt(question["topic_"+ questionindex +"_total"]) + 1

              for (var i = 0; i < total; i++) {

                let value = question["topic_"+ questionindex +"_value_"+ i]

                if(value === 3 && questionindex > 0){
                  
                  // console.log("questionindex", questionindex)
                  // console.log("i", i)

                  let uniqueid = questionindex +"_"+ i
                  let header = question["topic_"+ questionindex +"_questionlabel_"+ i]
                  let answer = question["topic_"+ questionindex +"_question_"+ i]
                  let comment = question["topic_"+ questionindex +"_comment_"+ i]
                  let questionid = question["topic_"+ questionindex +"_questionid_"+ i]
                  let questionrapporttext = question["topic_"+ questionindex +"_questionrapporttext_"+ i]

                  let prioComments
                  if(survey["questionPrioComments"]) {
                    prioComments = survey.questionPrioComments[questionindex +"_"+ i]
                  }
                  let showInRapport
                  let showInRapportHsr = question["topic_"+ questionindex +"_questionshowinrapport_"+ i] 
                  if(survey.showInRapport){
                    showInRapport = survey.showInRapport[uniqueid +"_question"]
                  } 
                  let showInRapportComment = survey.showInRapport[questionindex +"_"+ i +"_comment"]
                  let showInRapportCommentPrio = survey.showInRapport[uniqueid +"_commentPrio"]
                  let showInRapportActions = survey.showInRapport[uniqueid +"_actions"]
                  

                  let commentSpan = 12
                  if(comment){
                      commentSpan = 24
                  }

                  let commentSpanPrio = 12
                  if(prioComments){
                    commentSpanPrio = 24
                  }
                
                  if(showInRapport === undefined || showInRapport !== false && showInRapportHsr === true){

                    panels.push(
                      <>
                      {questionrapporttext && <Row>
                        <Col className="printSettings" style={{backgroundColor: 'lightGrey', paddingTop: 20, margin:0, paddingBottom : 10 }}>
                          <Row>
                            <Col offset={4} span={14}>
                              <h4>{questionrapporttext}</h4>
                            </Col>
                          </Row>
                        </Col>
                      </Row>}
                      <Row gutter={16}>
                        <Col offset={4} span={14}>                           
                            <br></br>
                            {/* <h3>Svar: {answer}</h3> */}
                            <br></br>

                            <Row gutter={16}>

                              {showInRapportComment !== false &&(
                                  comment &&(<Col span={commentSpan}>
                                  <h5>{that.props.language === 'en' ? 'Your comments from the survey' : 'Era kommentarer från kartläggning'}</h5>
                                  <p style={{paddingBottom: '10px'}}>{comment}</p>
                                  </Col>)
                                )
                              }
                            
                              {showInRapportCommentPrio !== false &&(
                                  prioComments &&(<Col span={commentSpanPrio}>
                                    <h5>{that.props.language === 'en' ? 'Your comments from prioritization' : 'Era kommentarer från prioritering'}</h5>
                                    <p style={{paddingBottom: '10px'}}>{prioComments && prioComments.comment}</p>
                                  </Col>)
                                )
                              }
                            </Row>
                            
                            {showInRapportActions !== false &&(
                                <Row gutter={24}><Col span={24}>
                                  <ActionItem prismicCtx={prismic}  uniqueid={uniqueid} questionid={questionid} surveyid={survey.uid} showExtra={false} showDetails={true} language={lang}/>
                                </Col></Row>
                              )
                            }
                              
                        </Col>
                      </Row>
                      </>
                    )

                  }
                } 
              }

            })
            }
            
            <Card>
              <Row gutter={18}>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <Col span={3} offset={6}>
                  <img src={`/static/media/icons/socioekonomi.png`} style={style}/> 
                </Col>
                <Col span={3}>
                  <img src={`/static/media/icons/turism.png`} style={style}/> 
                </Col>
                <Col span={3}>
                  <img src={`/static/media/icons/nedskrapning.png`} style={style}/> 
                </Col>
                <Col span={3}>
                  <img src={`/static/media/icons/avloppsnat.png`} style={style}/> 
                </Col>
              </Row>
              <br></br>
              <Row gutter={2}>
                <Col span={3} offset={6}>
                  <img src={`/static/media/icons/insamling.png`} style={style}/> 
                </Col>
                <Col span={3}>
                  <img src={`/static/media/icons/soptunna.png`} style={style}/> 
                </Col>
                <Col span={3}>
                  <img src={`/static/media/icons/industri.png`} style={style}/> 
                </Col>
                <Col span={3}>
                  <img src={`/static/media/icons/jordbruk.png`} style={style}/> 
                </Col>
              </Row>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <Row gutter={18}>
                <Col span={14} offset={4} style={{textAlign: "center"}}>
                  
                  
                  <h1>{this.props.language === 'en' ? 'Report from mapping of sources and routes of distribution for marine debris' : 'Rapport från kartläggning av källor och spridningsvägar för marint skräp'}</h1>
                </Col>
              </Row>
              <Row gutter={18}>
                <Col span={14} offset={4} style={{textAlign: "center"}}>
                  {/* <h3>{this.props.authUser.secondCity}</h3> */}
                  <h3>{monthNames[d.getMonth()]} {d.getFullYear()}</h3>
                </Col>
              </Row>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            
              <Row gutter={18}>
                <Col span={14} offset={4} style={{textAlign: "center"}}>
                <img src={`/static/media/images/logo.jpg`} style={styleLogo}/> 
                </Col>
              </Row>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <Row gutter={18}>
                <Col span={14} offset={4} style={{textAlign: "center"}}>
                  <h3>{this.props.language === 'en' ? 'Workgroup' : 'Arbetsgrupp'} </h3>
                  <h3>{monthNames[d.getMonth()]} {d.getFullYear()}</h3>
                </Col>
              </Row>
              <Row gutter={18}>
                <Col offset={4} span={14} style={{textAlign: "left"}}>
                  {/* <h5>Ansvarig för kartläggning:</h5> */}
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nulla lacus, vehicula id imperdiet cursus, iaculis eu urna.</p> */}
                  <h5>{this.props.language === 'en' ? 'Participants' : 'Deltagare'}:</h5>
                  <p>
                    {survey.workgroupnames}
                  </p>
                </Col>
              </Row>
              <br></br>
              <br></br>
          
              <Row gutter={18}>
                <Col offset={4} span={14}>

                  <h3>{this.props.language === 'en' ? 'The method for mapping sources and distribution routes for marine debris has been developed in the EU project BLASTIC.' : 'Metoden för kartläggning av källor och spridningsvägar för marint skräp är framtagen i EU-projektet BLASTIC.'}</h3>
{/* 
                  <p>Projektet pågick mellan 2016-2018 och finansierades av Centrala Östersjöprogrammet. Förutom Håll Sverige Rent som ledde projektet deltog även Håll Skärgården Ren (Finland), IVL Svenska Miljöinstitutet, Stockholm Environmental Institute Tallin, SYKE (Finland), Fee Lativa, Åbo kommun och Tallins kommun. <br></br><br></br>Det digitala verktyget för kartläggningen har finansierats av Naturvårdsverket.<br></br><br></br>Kartläggningen fungerar som en vägledning för kommuner som själva står för ifyllda svar och prioritering av åtgärder.</p> */}

                  <p>{this.props.language === 'en' ? 'The project lasted between 2016-2018 and was funded by the Central Baltic Sea Program. In addition to Keep Sweden Clean, which led the project, Håll Skärgården Ren (Finland), IVL Swedish Environmental Institute, Stockholm Environmental Institute Tallinn, SYKE (Finland), Fee Lativa, Turku Municipality and Tallinn Municipality also participated.' : 'Projektet pågick mellan 2016-2018 och finansierades av Centrala Östersjöprogrammet. Förutom Håll Sverige Rent som ledde projektet deltog även Håll Skärgården Ren (Finland), IVL Svenska Miljöinstitutet, Stockholm Environmental Institute Tallin, SYKE (Finland), Fee Lativa, Åbo kommun och Tallins kommun.'}</p>

                  <p>{this.props.language === 'en' ? 'The digital tool for the survey has been financed by the Swedish Environmental Protection Agency. The survey serves as a guide for municipalities that are responsible for completed answers and prioritization of measures.' : 'Det digitala verktyget för kartläggningen har finansierats av Naturvårdsverket. Kartläggningen fungerar som en vägledning för kommuner som själva står för ifyllda svar och prioritering av åtgärder.'}</p>

                </Col>
              </Row>
              <br></br>
              <br></br>
          
              <Row gutter={18}>
                <Col offset={4} span={14}>

                  <h3 style={{textAlign: "center"}}>{this.props.language === 'en' ? 'About Keep Sweden Tidy' : 'Om Håll Sverige Rent'}</h3>

                  {/* <p>Skräpet finns överallt – i staden, i naturen och i havet. Håll Sverige Rent arbetar för att förebygga och motverka nedskräpningen. Vi samlar kunskap, driver opinion och tar fram konkreta verktyg och lösningar för att stoppa skräpet. <br></br><br></br>Håll Sverige Rent är en ideell obunden stiftelse. Arbetet finansieras av bidrag och projektmedel från företag och myndigheter samt genom insamling till 90-konto. Håll Sverige Rent är medlem i Giva Sverige och verksamheten granskas av Svensk Insamlingskontroll.</p> */}

                  {this.props.language === 'en' ? 'Rubbish is everywhere - in the city, in nature and in the sea. Keep Sweden Clean works to prevent and counteract littering. We gather knowledge, drive opinion and develop concrete tools and solutions to stop the rubbish. Keep Sweden Clean is a non-profit foundation. The work is financed by grants and project funds from companies and authorities as well as through fundraising for 90-account. Keep Sweden Clean is a member of Giva Sverige and the business is reviewed by the Swedish Fundraising Control.' : 'Skräpet finns överallt – i staden, i naturen och i havet. Håll Sverige Rent arbetar för att förebygga och motverka nedskräpningen. Vi samlar kunskap, driver opinion och tar fram konkreta verktyg och lösningar för att stoppa skräpet. Håll Sverige Rent är en ideell obunden stiftelse. Arbetet finansieras av bidrag och projektmedel från företag och myndigheter samt genom insamling till 90-konto. Håll Sverige Rent är medlem i Giva Sverige och verksamheten granskas av Svensk Insamlingskontroll.'}

                </Col>
              </Row>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            
              <Row gutter={18}>
                <Col span={14} offset={4} style={{textAlign: "center"}}>
                <img src={`/static/media/images/logo.jpg`} style={styleLogo}/> 
                </Col>
              </Row>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <Row gutter={16}>
                <Col offset={4} span={14}>
                  <h1 style={{textAlign: "center"}}>{this.props.language === 'en' ? 'Priority areas' : 'Prioriterade områden'}</h1>
                  <p >
                  {this.props.language === 'en' ? 'Here are areas and issues that were prioritized in the municipality´s mapping of sources and distribution routes for marine debris.' : 'Här är områden och frågor som blev prioriterade i kommunens kartläggning av källor och spridningsvägar för marint skräp.'}
                    </p>
                </Col>
              </Row>
              {panels}

            </Card>

            <Row gutter={16} class="no-print">

              <Col span={18}>             
              </Col>
              <Col span={6} style={{textAlign: 'right'}}>
                {/* <button onClick={this.printMe} class="no-print ant-btn ant-btn-primary ant-btn-lg"><Icon type="file-pdf"/> Spara som PDF</button>
                  &nbsp; */}
                <button onClick={this.printMe} class="no-print ant-btn ant-btn-primary ant-btn-lg"><Icon type="printer" /> {this.props.language === 'en' ? 'Print' : 'Skriv ut'}</button>
                
              </Col>
            </Row>
        </>
      )
    }else{
      return null
    }
    

  }
}



const condition = authUser => !!authUser;

export default compose(
  withLocalize,
  withFirebase,
  withAuthorization(condition)

)(Raport);

